    module.exports = {
    headline: "Ordine di riparazione",
    thanks: "Grazie per aver contattato Aquarea+ Service!",
    text: "Il suo ordine di riparazione è stato completato con successo. Dopo aver completato il pagamento, troverà la fattura nella sua casella di posta elettronica.",
    invoiceAmountText: "Importo della fattura: ",
    isPaidText: "L'ordine di riparazione è stato completato con successo e la fattura è stata elaborata con successo.",
    noInvoiceText: "L'ordine di riparazione è stato completato con successo e la fattura è stata elaborata con successo.",
    payButton: "Paga adesso",
    pendingButtonText: "In Corso",
};
