import React, {useContext, useEffect} from "react";
import {Redirect} from 'react-router-dom';

import useNavigation from "../../lib/hooks/stepform/navigation";
import useCheckoutSession from "../../lib/hooks/stepform/checkoutSession";
import {ProtectionPlanContext} from "../../lib/contexts/protectionPlanContext";
import {ErrorResponseHandling} from "../../lib/dataAccess/errorResponseHandling";

import i18 from "../../lib/i18n";
import Hotline from "../../components/hotline";

// some steps
import ConnectionCheckStep from "./steps/connectionCheckStep";
import ChoiceList from "./choiceList";
import AccountInformationStep from "./steps/accountInformationStep";
import PaymentDetailsStep from "./steps/paymentDetailsStep";
import {PageBreadcrumb, PageHeader, PageHeadline} from "../layout/page";
import {StepFormNav} from "./stepFormNav";
import {FormContainer, FormSection} from "../layout/form";
import ConfirmationStep from "./steps/confirmationsStep";
import {CartContext} from "../../lib/contexts/cartContext";
import {ProductContext} from "../../lib/contexts/productContext";
import {AuthContext} from "../../lib/contexts/authContext";
import {
    ANONYMOUS_ID_SESSION_STORAGE_NAME,
    AUTH_TOKEN_SESSION_STORAGE_NAME,
    CART_ID_SESSION_STORAGE_NAME,
    COUNTRY_CODE_NAME,
    getSessionStorageItem,
    setSessionStorageItem
} from "../../lib/sessionStorage";
import {useHistory} from "react-router-dom/cjs/react-router-dom.min";
import {getCartFromServer} from '../../lib/dataAccess/checkout/cartRestEndpoint';
import {getProductByVariant} from "../../lib/helper/productHelper";

const countryCode = getSessionStorageItem(COUNTRY_CODE_NAME) || 'da_DK';
const t = i18.namespace("Stepform");
const p = i18.namespace("PlanCard");

const StepForm = (stepFormProps) => {
    const {protectionPlanContext} = useContext(ProtectionPlanContext);
    const {cartContext, setCartContext} = useContext(CartContext);
    const {productContext, setProductContext} = useContext(ProductContext);
    const {authContext, setAuthContext} = useContext(AuthContext);

    const history = useHistory();
    const steps = t("steps");
    const summary = t("summary");
    const planCardList = p("list");
    const navigation = useNavigation({steps: steps, initialStep: 1});
    const session = useCheckoutSession();
    const currentPlanCard = planCardList[protectionPlanContext];
    let currentStep = navigation.currentStep();
    const currentStepValues = steps[currentStep];
    const currentSessionValues = session.getValuesByStep(currentStep);


    async function getCart() {
        let tempStep = currentStep;
        currentStep = "loading";
        await getCartFromServer(getSessionStorageItem(ANONYMOUS_ID_SESSION_STORAGE_NAME), getSessionStorageItem(AUTH_TOKEN_SESSION_STORAGE_NAME)).then((result) => {
            setCartContext(result.data);
            currentStep = tempStep;
        }).catch(e => {
            currentStep = "error";
            ErrorResponseHandling(e, authContext, setAuthContext, history);
        });
    };

    function getProductContext() {
        if (!productContext.selectedPlan) {
            const selectedProduct = getProductByVariant(productContext,cartContext.lineItems?.find(lineItem => lineItem.sku.startsWith("contract-"))?.sku);
            setProductContext(prevState => {
                return {...prevState, selectedPlan: selectedProduct?.sku, selectedVariant:cartContext.lineItems?.find(lineItem => lineItem.sku.startsWith("contract-"))?.sku}
            });
        }
    }

    const updateSessionValues = () => {
        let baseUnit = authContext.accountData.installedBaseUnits.filter(b => {
                return b.id === cartContext.lineItems.find(lineItem => lineItem.sku.startsWith("contract-"))?.installedBaseUnitId
            });
        if(baseUnit && baseUnit[0]){
            session.updateValuesForStep([
                {key: "serialnumber", value: baseUnit[0].serialNumber, label: ""},
                {key: "installationDate", value: baseUnit[0].installationDate, label: ""},
                {key: "model", value: baseUnit[0].deviceType, label: ""},
                {key: "gatewayid", value: baseUnit[0].gatewayId, label: ""},
                {key: "internetConnected", value: baseUnit[0].internetConnected, label: ""},
                {key: "internetReady", value: baseUnit[0].internetReady, label: ""}], 0);
        }
        session.updateValuesForStep([
            {key: "email", value: authContext.accountData.email, label: ""},
            {key: "firstName", value: authContext.accountData.firstName, label: ""},
            {key: "lastName", value: authContext.accountData.lastName, label: ""},
            {key: "sendInvoice", value: authContext.accountData.sendInvoice, label: ""},
            {key: "street", value: cartContext.shippingAddress.street, label: ""},
            {key: "zipCode", value: cartContext.shippingAddress.zipCode, label: ""},
            {key: "city", value: cartContext.shippingAddress.city, label: ""},
            {key: "phoneNumber", value: cartContext.shippingAddress.phoneNumber, label: ""}], 1);
        setSessionStorageItem(CART_ID_SESSION_STORAGE_NAME, cartContext.id);
    }

    const checkConditionsForPayment = () => {
        const installedBaseUnitId = cartContext?.lineItems?.find(lineItem => lineItem?.sku.startsWith("contract-"))?.installedBaseUnitId;
        const baseUnit = authContext?.accountData?.installedBaseUnits?.find(baseUnit => baseUnit?.id === installedBaseUnitId);
        return cartContext?.id
            && cartContext?.lineItems?.length > 0
            && installedBaseUnitId
            && baseUnit
            && baseUnit?.internetReady != null
            && baseUnit?.internetConnected != null
            && cartContext?.shippingAddress
            && authContext?.accountData;
    }

    const navigateToStartStep = () => {
        if(stepFormProps.match.params.startStep === "payment" && checkConditionsForPayment()){
            updateSessionValues();
            navigation.setStep(2)
        } else {
            navigation.setStep(0);
        }
    }

    useEffect(() => {
        getCart();
        getProductContext();
        if (!(cartContext?.id || cartContext?.anonymousId)
            && !(getSessionStorageItem(CART_ID_SESSION_STORAGE_NAME) || getSessionStorageItem(ANONYMOUS_ID_SESSION_STORAGE_NAME))
        ) {
            history.push('/'+ countryCode +'/home/service_package');
        } else {
            navigateToStartStep();
        }
    }, []) // eslint-disable-line react-hooks/exhaustive-deps

    // TODO get some prevValues (hook->localStorage)
    const prevValues = null;

    const props = {
        navigation,
        session,
        currentStepValues,
        prevValues,
        currentSessionValues,
        currentStep,
        summary,
        currentPlanCard,
    };

    const renderStep = () => {
        switch (currentStep) {
            case 0:
                return <ConnectionCheckStep {...props}/>;
            case 1:
                return <AccountInformationStep {...props} />;
            case 2:
                return <PaymentDetailsStep {...props} />;
            case 3:
                return <ConfirmationStep {...props}/>
            case 4:
                return <div>
                    <button onClick={() => navigation.goNext()}>test</button>
                    test 5</div>;
            case "loading":
                return <div>
                    Loading ...
                </div>
            case "error":
            default:
                return <Redirect to={"404"}/>
        }
    }

    return (
        <div className="registration checkout step-form">
            <PageHeader>
                <div className="page_header_text_content">
                    <div className="page_header_text_content_left">
                        <PageBreadcrumb />
                        <PageHeadline headline={t("headline")} />
                    </div>
                    <Hotline />
                </div>
            </PageHeader>

            <div className="container">
                <StepFormNav steps={steps} currentStep={currentStep}/>
            </div>

            <div className="container checkout_page">
                <FormSection className="checkout_page_content">
                    <FormContainer>
                        {renderStep()}
                    </FormContainer>
                </FormSection>
                <ChoiceList/>
            </div>

        </div>
    )
}

export default StepForm;
