import React, {useContext, useEffect, useState} from 'react';
import {Link, useHistory} from 'react-router-dom';
import Menu from "../components/menu";
import Footer from "../components/footer";
import i18 from "../lib/i18n";
import Dropdown from "../components/dropdown";
import Breadcrumbs from "../lib/breadcrumbs";
import PlanComparisonTable from "../components/planComparisonTable";
import PlanServicePoints from "../components/planServicePoints";
import {
    ANONYMOUS_ID_SESSION_STORAGE_NAME,
    CART_ID_SESSION_STORAGE_NAME,
    COUNTRY_CODE_NAME,
    getSessionStorageItem,
    setSessionStorageItem
} from '../lib/sessionStorage';
import {changeCartContract, getCartFromServer, putCart} from '../lib/dataAccess/checkout/cartRestEndpoint';
import {CartContext} from '../lib/contexts/cartContext';
import {ProtectionPlanContext} from "../lib/contexts/protectionPlanContext";
import {FeatureIconList, FeatureIconListItem} from "../components/layout/featureIconList";
import {AuthContext} from "../lib/contexts/authContext";
import {ProductContext} from "../lib/contexts/productContext";
import {getProduct} from "../lib/dataAccess/product/productRestEndpoint";
import {getPriceForSku} from '../lib/helper/productHelper';
import {ErrorResponseHandling} from "../lib/dataAccess/errorResponseHandling";
import {OptionalValue} from "../components/layout/detailEntry";
import { LoadingSpinnerButton } from '../components/layout/buttons';
import Modal from '../components/modal';
import { BRONZE_CONTRACT_SKU, GOLD_CONTRACT_SKU, SILVER_CONTRACT_SKU, SUCCESS_STATUS } from '../lib/constants';
import { getAccount } from '../lib/dataAccess/accountService';

const countryCode = getSessionStorageItem(COUNTRY_CODE_NAME) || 'da_DK';
const t = i18.namespace("PlanCard");

function ProtectionPlans(props) {
    const {setProtectionPlanContext} = useContext(ProtectionPlanContext);
    const {productContext, setProductContext} = useContext(ProductContext);
    const {cartContext, setCartContext} = useContext(CartContext);
    const {authContext, setAuthContext} = useContext(AuthContext);
    const [isBaseUnitIneligible, setIsBaseUnitIneligible] = useState(false);
    const [selectedSkuName, setSelectedSkuName] = useState("");
    const [selectedId, setSelectedId] = useState(null);
    const [loadingButtonIndex, setLoadingButtonIndex] = useState(null);

    const history = useHistory();

    useEffect(() => {
        if (!productContext?.plans) {
          getProduct()
            .then((response) => {
              if (response.status === SUCCESS_STATUS) {
                setProductContext({plans: response?.data?.result});
              }
            })
            .catch((e) => {
              ErrorResponseHandling(e, authContext, setAuthContext, history);
            });
        }
    }, [productContext?.plans, setProductContext]);  // eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
      const initializeCartContext = async () => {
        if (!cartContext?.lineItems) {
          if (authContext.authToken) {
            await getCartFromServer(null, authContext.authToken)
              .then((response) => {
                if (response.status === SUCCESS_STATUS) {
                  setCartContext(response.data);
                }
              })
              .catch((e) => {
                ErrorResponseHandling(e, authContext, setAuthContext, history);
              });
          }
        }
      };
      initializeCartContext();
    }, [authContext, cartContext?.lineItems, setAuthContext, setCartContext]);  // eslint-disable-line react-hooks/exhaustive-deps

    async function handleClick(event, skuName, id) {
      event.preventDefault();
      setSelectedSkuName(skuName);
      setSelectedId(id);
      const bronzeLineItem = cartContext?.lineItems?.find((lineItem) =>
        lineItem?.sku.startsWith(BRONZE_CONTRACT_SKU)
      );
      if (
        bronzeLineItem &&
        (skuName === GOLD_CONTRACT_SKU || skuName === SILVER_CONTRACT_SKU)
      ) {
        const baseUnit = authContext?.accountData?.installedBaseUnits?.find(
          (baseUnit) => baseUnit.id === bronzeLineItem?.installedBaseUnitId
        );
        if (
          baseUnit &&
          baseUnit.internetReady === false &&
          baseUnit.internetConnected === false
        ) {
          setIsBaseUnitIneligible(true);
          return;
        }
      }
      await processCartUpdate(skuName, id);
    }

    async function processCartUpdate(skuName, id) {
      try {
        setLoadingButtonIndex(id);
        await updateCart(skuName);
        setProtectionPlanContext(id);
        setProductContext((prevState) => ({
          ...prevState,
          selectedPlan: skuName,
          selectedVariant: null,
        }));
      } catch (e) {
        ErrorResponseHandling(e, authContext, setAuthContext, history);
      } finally {
        setLoadingButtonIndex(null);
      }
    }

    async function updateCart(skuName) {
      const anonymousId =
        getSessionStorageItem(ANONYMOUS_ID_SESSION_STORAGE_NAME) || "";
      let serverResponse;

      try {
        if (cartContext?.lineItems?.length > 0) {
          serverResponse = await changeCartContract(
            skuName,
            false,
            anonymousId,
            authContext?.authToken
          ).catch((e) => {
            ErrorResponseHandling(e, authContext, setAuthContext, history);
          });
          if (authContext?.authToken) {
            await getAccount(authContext?.authToken)
              .then((response) => {
                if (response.status === SUCCESS_STATUS) {
                  setAuthContext({
                    ...authContext,
                    accountData: response.data,
                  });
                }
              })
              .catch((e) => {
                ErrorResponseHandling(e, authContext, setAuthContext, history);
              });
          }
        } else {
          serverResponse = await putCart(
            {
              sku: skuName,
              installedBaseUnitReference: "",
              anonymousId,
            },
            authContext?.authToken
          );
        }

        setCartContext(serverResponse.data);
        setSessionStorageItem(
          ANONYMOUS_ID_SESSION_STORAGE_NAME,
          serverResponse.data.anonymousId
        );
        setSessionStorageItem(
          CART_ID_SESSION_STORAGE_NAME,
          serverResponse.data.id
        );

        history.push("/" + countryCode + "/home/checkout");
      } catch (error) {
        ErrorResponseHandling(error, authContext, setAuthContext, history);
      }
    }

    function drawPrices(item) {
        let productVariantSkus = [];

        productContext?.plans?.forEach(product => {
            if (item.sku === product.sku) {
                productVariantSkus = product.variants
            }
        })

        productVariantSkus = productVariantSkus.reverse();

        if (productVariantSkus[0] !== undefined) {
            return productVariantSkus.map((productVariantSku, index) => {
                let span;
                if (index === 0) {
                    span =
                        <span key={index}>
                            <b>{getPriceForSku(productVariantSku.sku, productContext)}
                                {" " + t(productVariantSku.paymentPeriod)}</b><br/>
                        </span>
                } else {
                    span =
                        <span key={index}>
                            {getPriceForSku(productVariantSku.sku, productContext)}
                            {" " + t(productVariantSku.paymentPeriod)}<br/>
                        </span>
                }
                return span;
            });
        }
    }

    const modalContent = {
      title: t("modal.editContractTitle"),
      body: t("modal.editContractBody"),
    };

    const handleChangeContract = () => {
      setIsBaseUnitIneligible(false);
      processCartUpdate(selectedSkuName, selectedId);
    };

    const handleApplyComfort = () => {
      setIsBaseUnitIneligible(false);
      history.push("/" + countryCode + "/home/checkout/payment");
    };

    const handleClose = () => {
      setIsBaseUnitIneligible(false);
    };

    const renderModal = () => {
      return (
        <Modal
          id={"editContract"}
          content={modalContent}
          isOpen={isBaseUnitIneligible}
          handleClose={handleClose}
          primaryAction={{
            component: (
              <LoadingSpinnerButton
                isEnabled
                classNames="btn-primary order-1 order-md-2 loading-spinner-button"
                onClick={handleApplyComfort}
                text={t("modal.primaryButton")}
              />
            ),
          }}
          secondaryAction={{
            component: (
              <LoadingSpinnerButton
                isEnabled
                classNames="btn-secondary order-1 order-md-2"
                onClick={handleChangeContract}
                text={t("modal.secondaryButton")}
              />
            ),
          }}
        />
      );
    };

    return (
        <div>
            {renderModal()}
            <div className="page">
                <Menu/>
                <div className="page_header container">
                    <section className="breadcrumbs">
                        <Breadcrumbs/>
                    </section>

                    <h1 className="planCard_headline">{t("headline")}</h1>
                </div>
                <div className="page_body">
                    <section className="planCard">
                        <div className="container_for_desktop">


                            <ul className="planCard_list planCard_list_mobil">
                                {
                                    t("list").map((items, i) =>
                                        <li key={i} className={"planCard_list_item planCard_list_item__" + items.color}>
                                            <div className="planCard_list_box">
                                                <img className="planCard_list_headline" src={items.headline} alt=""/>
                                                <div className="planCard_list_image"
                                                     style={{backgroundImage: `url(${items.image})`}}>
                                                </div>
                                                <div className="planCard_list_image_plusicon"/>
                                            </div>
                                            <div className="planCard_list_box">
                                                <p className="planCard_list_subheadline">
                                                    {drawPrices(items)}
                                                </p>

                                                <Dropdown label={t("desc_label")} text={items.text}/>

                                                <Dropdown label={t("terms_label")} text={<Link className="planCard_list_link" to={items.linkHref}>{items.link}</Link>}/>

                                                <FeatureIconList>
                                                    {
                                                        items.iconList.map(({label, alt, icon}, index) =>
                                                            <FeatureIconListItem key={index} label={label} icon={icon}
                                                                                 alt={alt}/>
                                                        )
                                                    }
                                                </FeatureIconList>
                                                <OptionalValue condition={items.sku ==='contract-heatpump-gold'} emptyValue="">
                                                    <p className="planCard_list_text">{items.disclaimer}</p>
                                                </OptionalValue>

                                                <div className="planCard_list_btns">
                                                    <a className="planCard_list_btns_btn planCard_list_btns_btn--secondary"
                                                       href="#plan-comparison-table">
                                                        {items.btn_goTo.label}
                                                    </a>

                                                    <LoadingSpinnerButton
                                                        isEnabled
                                                        text={items.btn_book.label}
                                                        isLoading={loadingButtonIndex === i}
                                                        classNames="planCard_list_btns_btn order-1 order-md-2 loading-spinner-button"
                                                        onClick={(event) => handleClick(event, items.sku, i)}
                                                    />

                                                </div>
                                            </div>
                                        </li>
                                    )
                                }
                            </ul>

                            <ul className="planCard_list planCard_list_desktop">
                                {
                                    t("list").map((items, i) =>
                                        <li key={i} className={"planCard_list_item planCard_list_item__" + items.color}>
                                            <div key={i} className="planCard_list_box">
                                                <img className="planCard_list_headline" src={items.headline} alt=""/>
                                                <p className="planCard_list_subheadline">
                                                    {drawPrices(items)}
                                                </p>
                                                <p className="planCard_list_text">
                                                    {items.text}
                                                </p>
                                                <p className="planCard_list_info">
                                                    {items.info}
                                                </p>

                                                <FeatureIconList>
                                                    {
                                                        items.iconList.map(({label, alt, icon}, index) =>
                                                            <FeatureIconListItem key={index} label={label} icon={icon}
                                                                                 alt={alt}/>
                                                        )
                                                    }
                                                </FeatureIconList>

                                                <OptionalValue condition={items.sku ==='contract-heatpump-gold' ||
                                                                          items.sku ==='contract-heatpump-silver'}
                                                               emptyValue="">
                                                    <p className="planCard_list_text">{items.disclaimer}</p>
                                                </OptionalValue>
                                                <div className="planCard_list_listlabel p">
                                                    {items.good_to_know_title}
                                                    <ul className="planCard_list_list list-unstyled">
                                                        {
                                                            items.good_to_know_list.map((items, i) =>
                                                                <li key={i} className="planCard_list_list_item">
                                                                    {items.label}
                                                                </li>
                                                            )
                                                        }
                                                    </ul>
                                                </div>
                                                <Link className="planCard_list_link" to={items.linkHref}>{items.link}</Link>
                                                <div className="planCard_list_btns">
                                                    <a className="planCard_list_btns_btn planCard_list_btns_btn--secondary"
                                                       href="#plan-comparison-table">
                                                        {items.btn_goTo.label}
                                                    </a>
                                                    <LoadingSpinnerButton
                                                        isEnabled
                                                        text={items.btn_book.label}
                                                        isLoading={loadingButtonIndex === i}
                                                        classNames="planCard_list_btns_btn order-1 order-md-2 loading-spinner-button"
                                                        onClick={(event) => handleClick(event, items.sku, i)}
                                                    />
                                                </div>
                                            </div>
                                            <div className="planCard_list_box">
                                                <div className="planCard_list_image"
                                                     style={{backgroundImage: `url(${items.image})`}}>
                                                </div>
                                                <div className="planCard_list_image_plusicon"/>
                                            </div>
                                        </li>
                                    )
                                }
                            </ul>
                        </div>
                    </section>
                    <PlanServicePoints/>
                    <PlanComparisonTable/>
                </div>
                <Footer/>
            </div>
        </div>
    )
}

export default ProtectionPlans;
