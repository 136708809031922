module.exports = {
    headline: "Sortiment af servicepakker",
    desc_label: "Beskrivelse",
    terms_label: "Vilkår og betingelser",
    annually: 'pr. år',
    quarterly: 'pr. kvartal',
    monthly: 'pr. måned',
    list: [
        {
            headline: '/image/plan_cards/aquarea_service+_exclusive.svg',
            choiceList_image: "/image/plan_cards/aquarea_service+_exclusive.svg",
            subheadline: 'pr. måned',
            sku: "contract-heatpump-gold",
            color: "yellow",
            image: "/image/plan_cards/1.jpg",
            imageiconlist: "/image/test/screen9.png",
            text: "Med et Aquarea Service + Premium abonnement har du en bekymring mindre. Din varmepumpe bliver jævnligt fjernovervåget. Hvis der opstår en fejl, er vi de første til at vide det. Vi kontakter dig med det samme og hjælper med at løse problemet på afstand eller hvis dette ikke er muligt, koordinerer vi et servicebesøg og dækker Panasonics omkostninger for arbejde og reservedele.",
            disclaimer: "Når du køber servicepakke \"Aquarea + Premium\", får du \"Aquarea + Smart\", indtil Panasonics tekniker kontrollerer installationen og godkender, at vigtige tekniske krav er opfyldt. Hvis du ikke opfylder disse tekniske krav, vil du forblive og nyde fordelene ved \"Aquarea + Smart\"-pakken. Indtil din installation er godkendt til \"Aquarea + Premium\", bliver du opkrævet for \"Aquarea + Smart\"-pris.\n",
            info: "Du skal bare læne dig til bare og nyde den komfortable temperatur i dit hjem.",
            good_to_know_title: "Godt at vide",
            good_to_know_list: [
                {
                    label: "Kan annulleres på årlig basis"
                },
            ],
            link: "Download alle vilkår",
            linkHref: "/da_DK/home/terms_and_conditions",
            btn_goTo: {
                href: "",
                label: "Gå til sammenligning"
            },
            btn_book: {
                href: "",
                label: "Bestil Premium"
            },
            iconList: [
                {
                    icon: "/image/icons/services/repair_discount.svg",
                    alt: "",
                    label: "Arbejdstid inkluderet",
                },
                {
                    icon: "/image/icons/services/repair_discount.svg",
                    alt: "",
                    label: "Gratis reservedele",
                },
                {
                    icon: "/image/icons/services/permanent_monitoring.svg",
                    alt: "",
                    label: "Svartid inden for 24 timer",
                },
                {
                    icon: "/image/icons/services/247_call.svg",
                    alt: "",
                    label: "24/7 ekslusiv telefonisk support",
                },
                {
                    icon: "/image/icons/services/maintenance.svg",
                    alt: "",
                    label: "Årlig vedligeholdelse",
                }
            ]
        },
        {
            headline: '/image/plan_cards/aquarea_service+_advanced.svg',
            choiceList_image: "/image/plan_cards/aquarea_service+_advanced.svg",
            subheadline: 'pr. måned',
            color: "green",
            sku: "contract-heatpump-silver",
            image: "/image/plan_cards/3.jpg",
            imageiconlist: "/image/test/screen8.png",
            text: "Med dit Aquarea Service + Smart abonnement har du en bekymring mindre. Din varmepumpe bliver jævnligt fjernovervåget. Hvis der opstår en fejl, er vi de første til at vide det. Vi kontakter dig med det samme og løser problemet på afstand, eller hvis det ikke er muligt, koordinerer vi et servicebesøg.",
            info: "Du skal bare læne dig til bare og nyde den komfortable temperatur i dit hjem.",
            good_to_know_title: "Godt at vide",
            good_to_know_list: [
                {
                    label: "Kan annulleres på årlig basis"
                },
            ],
            link: "Download alle vilkår",
            linkHref: "/da_DK/home/terms_and_conditions",
            btn_goTo: {
                href: "",
                label: "Gå til sammenligning"
            },
            btn_book: {
                href: "",
                label: "Bestil Smart"
            },
            iconList: [
                {
                    icon: "/image/icons/services/repair_discount.svg",
                    alt: "",
                    label: "Dagligt kontroltjek",
                },
                {
                    icon: "/image/icons/services/permanent_monitoring.svg",
                    alt: "",
                    label: "Svartid inden for 24 timer",
                },
                {
                    icon: "/image/icons/services/247_call.svg",
                    alt: "",
                    label: "24/7 eksklusiv telefonisk support",
                },
                {
                    icon: "/image/icons/services/maintenance.svg",
                    alt: "",
                    label: "Årlig vedligeholdelse",
                }
            ]
        },
        {
            headline: '/image/plan_cards/aquarea_service+_smart.svg',
            choiceList_image: "/image/plan_cards/aquarea_service+_smart.svg",
            subheadline: 'pr. måned',
            color: "brown",
            sku: "contract-heatpump-bronze",
            image: "/image/plan_cards/2.jpg",
            imageiconlist: "/image/test/screen7.png",
            text: "Med et Aquarea Service+ Basic-abonnement får du en servicepakke, der inkluderer eksklusiv support over telefon og et årligt vedligeholdelsesbesøg af en af vores teknikere.",
            info: "Du skal bare læne dig til bare og nyde den komfortable temperatur i dit hjem. ",
            good_to_know_title: "Godt at vide",
            good_to_know_list: [
                {
                    label: "Kan annulleres på årlig basis"
                },
            ],
            link: "Download alle vilkår",
            linkHref: "/da_DK/home/terms_and_conditions",
            btn_goTo: {
                href: "",
                label: "Gå til sammenligning"
            },
            btn_book: {
                href: "",
                label: "Bestil Basic"
            },
            iconList: [
                {
                    icon: "/image/icons/services/call.svg",
                    alt: "",
                    label: "Eksklusiv telefonisk support",
                },
                {
                    icon: "/image/icons/services/maintenance.svg",
                    alt: "",
                    label: "Årlig vedligeholdelse",
                }
            ]
        },

    ],
    modal: {
        editContractTitle: "Rediger Kontrakt",
        editContractBody: "Med den konfiguration, du har valgt, er det kun Aquarea Service + Comfort-kontrakten, der gælder.",
        primaryButton: "Anvend Comfort",
        secondaryButton: "Ændre Kontrakt",
    }
};
