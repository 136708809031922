module.exports = {
    headline: "Aquarea Service+ kontrakt",
    baseUnit: "Installeret enhed",
    contract: "Kontrakt",
    month:"måned",
    monthly:"måned",
    quarterly:"kvartal",
    annually:"år",
    disclaimerHeadline :"Aquarea+ Premium Service: Afventer teknisk sundheds- og driftstjek",
    disclaimer:"Når du køber servicepakke \"Aquarea + Premium\", får du \"Aquarea + Smart\", indtil Panasonics tekniker kontrollerer installationen og godkender, at vigtige tekniske krav er opfyldt. Hvis du ikke opfylder disse tekniske krav, vil du forblive og nyde fordelene ved \"Aquarea + Smart\"-pakken. Indtil din installation er godkendt til \"Aquarea + Premium\", bliver du opkrævet for \"Aquarea + Smart\"-pris.\n",
    contract_complete: "Denne kontrakt er allerede opfyldt.",
    contract_cancelled: "Denne kontrakt er blevet annulleret.",
    waitingForDateUpdateStatus:"Venter på svar fra betalingsservicesystemet",
    updateExpiryDate:'Udløbsdato skal fornyes',
    updateFailed:'Opdatering af udløbsdato mislykkedes - prøv igen senere',
    updateSuccess:'Exp.Date updated',
    details: {
        model: "model",
        orderNumber: "Ordrenummer",
        installationDate: "installationsdato",
        protectionPlan: "servicepakke",
        location: "beliggenhed",
        serialNumber: "serienummer",
        contractValidTill: "kontrakten er gyldig indtil den",
        costs: "omkostninger",
        creditCardNumber:"kreditkortnummer",
        paymentMethod:"betalingsmetode",
        expiryDate:'Udløbsdato',
        expiryValue:'GYLDIG TIL OG MED'
    },
    btn:{
        upgrade: "Opgrader din servicepakke",
        cancel: "Afmeld din servicepakke",
        payment_update: "ændre betalingsmetode",
        registerHeatpump: "register varmepumpe",
        registerYourDevice: "registrere din enhed",
        expiry_update:'OPDATER UDLØBSDATO',
        updateAllContracts:'Ja Opdatering',
        cancelUpdate:'Nej',
    },
    campaign: {
        img: "/image/home/hero/PaSo-GW@2x.png",
        alt: "alt green week img text"
    },
    modal:{
        updateContractsTitle:"Opdatering af kontrakt(er)",
        updateContractsBody:"Vil du opdatere udløbsdatoen på din(e) kontakt(er)?",
    },
    accountPage:'/da_DK/home/my_account'
}
