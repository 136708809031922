module.exports = {
    headline: "Utbud av servicepaket",
    desc_label: "Beskrivning",
    terms_label: "Villkor och bestämmelser",
    annually: ' / år',
    quarterly: ' / kvartal',
    monthly: '/ månad',
    list: [
        {
            headline: '/image/plan_cards/aquarea_service+_exclusive.svg',
            choiceList_image: "/image/plan_cards/aquarea_service+_exclusive.svg",
            subheadline: 'per månad',
            sku: "contract-heatpump-gold",
            color: "yellow",
            image: "/image/plan_cards/1.jpg",
            imageiconlist: "/image/test/screen9.png",
            text: "Med din Aquarea Service+ Premium-prenumeration har du ett bekymmer mindre. Din värmepump övervakas regelbundet på distans. Om ett fel uppstår är vi de första som får veta det. Vi kontaktar dig omedelbart och hjälper till att lösa problemet på distans eller om detta inte är möjligt samordnar vi ett servicebesök och täcker Panasonics kostnader för arbete och reservdelar.",
            disclaimer: "Vänligen notera att en Användare som beställer ett Premium-abonnemang kommer att få ett Smart-abonnemang tills dess att Panasonic har verifierat och kontrollerat installationen. När det bekräftats att de huvudsakliga tekniska kraven har uppfyllts kan Panasonic bevilja användarens ansökan om Premium-abonnemang. Om Användaren inte uppfyller de tekniska kraven för Premium-abonnemanget kommer Användaren fortsatt kunna nyttja Smart-abonnemangets fördelar. Användaren kommer initial att debiteras avgiften för Smart-abonnemanget och om Användarens ansökan om Premium-abonnemang beviljas av Panasonic kommer Användaren fortsättningsvis att debiteras avgiften för Premium-abonnemanget.\n",
            info: "Du behöver inte göra något annat än att njuta av komforten i ditt hem.",
            good_to_know_title: "Bra att veta",
            good_to_know_list: [
                {
                    label: "Avbeställningsbart på årsbasis"
                },
            ],
            link: "Ladda ner fullständiga villkor",
            linkHref: "/sv_SE/home/terms_and_conditions",
            btn_goTo: {
                href: "",
                label: "Gå till jämförelsetabellen"
            },
            btn_book: {
                href: "",
                label: "Ansök om Premium"
            },
            iconList: [
                {
                    icon: "/image/icons/services/repair_discount.svg",
                    alt: "",
                    label: "Arbetstid ingår",
                },
                {
                    icon: "/image/icons/services/repair_discount.svg",
                    alt: "",
                    label: "Gratis reservdelar",
                },
                {
                    icon: "/image/icons/services/permanent_monitoring.svg",
                    alt: "",
                    label: "24 timmars svarstid",
                },
                {
                    icon: "/image/icons/services/247_call.svg",
                    alt: "",
                    label: "24/7 prioriterad telefonsupport",
                },
                {
                    icon: "/image/icons/services/maintenance.svg",
                    alt: "",
                    label: "Årligt underhåll",
                }
            ]
        },
        {
            headline: '/image/plan_cards/aquarea_service+_advanced.svg',
            choiceList_image: "/image/plan_cards/aquarea_service+_advanced.svg",
            subheadline: 'per månad',
            color: "green",
            sku: "contract-heatpump-silver",
            image: "/image/plan_cards/3.jpg",
            imageiconlist: "/image/test/screen8.png",
            text: "Med din Aquarea Service+ Smart-prenumeration har du ett bekymmer mindre. Din värmepump övervakas regelbundet på distans. Om ett fel uppstår är vi de första som får veta det. Vi kontaktar dig omedelbart och löser problemet på distans eller om det inte är möjligt samordnar vi ett servicebesök.",
            info: "Du behöver inte göra något annat än att njuta av komforten i ditt hem.",
            good_to_know_title: "Bra att veta",
            good_to_know_list: [
                {
                    label: "Avbokningsbart på årsbasis"
                },
            ],
            link: "Ladda ner fullständiga villkor",
            linkHref: "/sv_SE/home/terms_and_conditions",
            btn_goTo: {
                href: "",
                label: "Gå till jämförelsetabellen"
            },
            btn_book: {
                href: "",
                label: "Beställ Smart"
            },
            iconList: [
                {
                    icon: "/image/icons/services/repair_discount.svg",
                    alt: "",
                    label: "Daglig kontroll",
                },
                {
                    icon: "/image/icons/services/permanent_monitoring.svg",
                    alt: "",
                    label: "Svarstid inom 24 timmar",
                },
                {
                    icon: "/image/icons/services/247_call.svg",
                    alt: "",
                    label: "24/7 prioriterad telefonsupport",
                },
                {
                    icon: "/image/icons/services/maintenance.svg",
                    alt: "",
                    label: "Årligt underhåll",
                }
            ]
        },
        {
            headline: '/image/plan_cards/aquarea_service+_smart.svg',
            choiceList_image: "/image/plan_cards/aquarea_service+_smart.svg",
            subheadline: 'per månad',
            color: "brown",
            sku: "contract-heatpump-bronze",
            image: "/image/plan_cards/2.jpg",
            imageiconlist: "/image/test/screen7.png",
            text: "Med ditt Aquarea Service+ Basic-abonnemang får du ett bekvämt paket med tillgång till prioriterad telefonsupport och ett årligt underhållsbesök på plats.",
            info: "Du behöver inte göra något annat än att bara njuta av komforten i ditt hem.",
            good_to_know_title: "Bra att veta",
            good_to_know_list: [
                {
                    label: "Avbokningsbart på årsbasis"
                },
            ],
            link: "Ladda ner fullständiga villkor",
            linkHref: "/sv_SE/home/terms_and_conditions",
            btn_goTo: {
                href: "",
                label: "Gå till jämförelsetabellen"
            },
            btn_book: {
                href: "",
                label: "Beställ Basic"
            },
            iconList: [
                {
                    icon: "/image/icons/services/call.svg",
                    alt: "",
                    label: "Prioriterad telefonsupport",
                },
                {
                    icon: "/image/icons/services/maintenance.svg",
                    alt: "",
                    label: "Årligt underhåll",
                }
            ]
        },
    ],
    modal: {
        editContractTitle: "Redigera Kontrakt",
        editContractBody: "Med den konfiguration du har valt är det endast Aquarea Service + Comfort-avtalet som gäller.",
        primaryButton: "Tillämpa Comfort",
        secondaryButton: "Ändra Kontrakt",
    }
};
