module.exports = {
    taxInformation: "Tax Information",
    details: {
        title: "Title",
        firstName: "First Name",
        lastName: "Last Name",
        name: "Name",
        email: "Email",
        sendInvoice: "Would you like to receive a paper invoice ?",
        address : "Address",
        zipCode : "Postcode",
        city: "City",
        phoneNumber: "Phone Number",
        phone: "Phone",
        taxCodeTypeEmptyOption: "Select tax code type",
        taxCodeType: "Tax Code Type",
        taxCode: "Tax Code",
    },
    btn: {
        edit: "Edit Personal Information",
        back: "back to my account",
        update: "Save",
        reset: "Reset Password"
    },
    editAccount: {
        headline: "Edit Personal Information"
    },
    loading: "Loading ..."
}
