module.exports = {
    headline: "Registrera värmepumpsenhet",
    date: {
        title: "Datum för installation av din värmepump",
        name: "datum för installation"
    },
    model: {
        title: "Vilken modell är installerad?",
        name: "MODELLNAMN",
        img: {
            linkText: "VAR HITTAR JAG?",
            path: "/image/checkout/heatpump.png"
        },
        emptyOption: "-- Välj modell --",
        options: [
            "Jag vet inte.",
            "WH-MDC05H3E5",
            "WH-MDC05J3E5",
            "WH-MDC06G3E5",
            "WH-MDC07H3E5",
            "WH-MDC07J3E5",
            "WH-MDC09H3E5",
            "WH-MDC09J3E5",
            "WH-MDC12H6E5",
            "WH-MHF09G3E8",
            "WH-MHF12G9E8",
            "WH-MXC09G3E8",
            "WH-MXC09H3E5",
            "WH-MXC09H3E8",
            "WH-MXC09J3E5",
            "WH-MXC09J3E8",
            "WH-MXC12G9E8",
            "WH-MXC12H9E8",
            "WH-MXC12J9E8",
            "WH-MXC16G9E8",
            "WH-MXC16H9E8",
            "WH-MXC16J9E8",
            "WH-UD03HE5-1",
            "WH-UD03JE5",
            "WH-UD05HE5-1",
            "WH-UD05JE5",
            "WH-UD07HE5-1",
            "WH-UD07JE5",
            "WH-UD09HE5-1",
            "WH-UD09HE8",
            "WH-UD09JE5",
            "WH-UD09JE5-1",
            "WH-UD12HE5",
            "WH-UD12HE8",
            "WH-UD16HE5",
            "WH-UD16HE8",
            "WH-UDZ03KE5",
            "WH-UDZ05KE5",
            "WH-UDZ07KE5",
            "WH-UDZ09KE5",
            "WH-UH09FE5",
            "WH-UH09FE8",
            "WH-UH12FE5",
            "WH-UH12FE8",
            "WH-UQ09HE8",
            "WH-UQ12HE8",
            "WH-UQ16HE8",
            "WH-UX09HE5",
            "WH-UX09HE8",
            "WH-UX12FE8",
            "WH-UX12HE5",
            "WH-UX12HE8",
            "WH-UX16HE8",
            "WH-UXZ12KE5",
            "WH-WDG05LE5",
            "WH-WDG07LE5",
            "WH-WDG09LE5"
        ]
    },
    serialNumber: {
        name: "SERIENUMMER",
        img: {
            linkText: "VAR HITTAR JAG?",
            path: "/image/checkout/heatpump.png",
        },
    },
    gatewayId: {
        title: "Om du redan har nätverksadaptern, ange enhetens ID",
        name: "DEVICE ID (FRIVILLIGT)",
        img: {
            linkText: "VAR HITTAR JAG?",
            path: "/image/checkout/deviceID.png"
        }
    },
    shippingAddress: {
        title: "Installationsadress",
        street: {
            label: "Adress",
            name: "street",
        },
        zipcode: {
            label: "Postnummer",
            name: "zipcode",
        },
        city: {
            label: "Ort",
            name: "city",
        },
    },
    btn: {
        back: "TILLBAKA TILL MITT KONTO",
        next: "SPARA"
    },
    errors: {
        futureDate: "installationsdatumet ligger i framtiden",
        dateTooFarInPast: "installationsdatumet är för tidigt",
        onlyDigits: "Endast siffror är tillåtna.",
        lengthIs11: "längden på device id måste vara 11",
        zipCode: "3 siffror tomt 2 siffror",
        serialMin: "Min. 8 sifre",
        serialMax: "Maks 20 sifre",
        requiredField: "Detta fält är obligatoriskt",
        addressTooLong: "Maksimalt 30 tegn",
        cityTooLong: "Maksimalt 35 tegn"
    }
};
