import React, {useState, useContext, useEffect} from 'react'
import {Link, withRouter} from "react-router-dom";
import i18 from "../lib/i18n";
import {AuthContext} from "../lib/contexts/authContext";
import Logout from "./logout";
import {
    AUTH_TOKEN_SESSION_STORAGE_NAME,
    CART_ID_SESSION_STORAGE_NAME,
    COUNTRY_CODE_NAME,
    getSessionStorageItem,
    setSessionStorageItem,
    removeSessionStorage,
} from "../lib/sessionStorage";
import { useLocation, useParams } from 'react-router-dom';
import {getFormattedCountryCode} from "../lib/helper/localizationHelper"
import Toggle from 'react-toggle';

const t = i18.namespace("Menu");

const Menu = (props) => {
    let { countryCode } = useParams();
    const { pathname, search } = useLocation();
    const storedCountryCode = localStorage.getItem("lastSelectedLanguageForSpain");
    useEffect(() => {
        const sessionCountryCode = getSessionStorageItem(COUNTRY_CODE_NAME)
        if(countryCode && getFormattedCountryCode(countryCode) !== sessionCountryCode){
            setSessionStorageItem(COUNTRY_CODE_NAME, getFormattedCountryCode(countryCode));
            if(getSessionStorageItem(CART_ID_SESSION_STORAGE_NAME) || getSessionStorageItem(AUTH_TOKEN_SESSION_STORAGE_NAME)){
                logout(getFormattedCountryCode(countryCode));
            }
            props.history.go(0);
        }
    }, []) // eslint-disable-line react-hooks/exhaustive-deps

    const { authContext, setAuthContext } = useContext(AuthContext);
    const [state, setState] = useState({ openMenu: false });
    const [isLocalesMenu, setIsLocalesMenu] = useState(false);
    const [isPopup, setIsPopup] = useState(false);
    const [countryLink, setCountryLink] = useState("")

    const logout = (countryCode) => {
        clearCustomerData();

        if(countryCode){
            props.history.push('/'+ countryCode +'/home');
        } else {
            props.history.push('/'+ getSessionStorageItem(COUNTRY_CODE_NAME) +'/home');
        }
    }

    function clearCustomerData() {
        removeSessionStorage();
        setAuthContext({ authToken: null, accountData: null });
    }

    function handleCountryChange() {
        setIsPopup(!isPopup)
        clearCustomerData()
        setSessionStorageItem(COUNTRY_CODE_NAME, getFormattedCountryCode(countryLink));
        props.history.push('/'+ countryLink +'/home')
        setIsLocalesMenu(false);
        window.location.reload();
    }

    function handleLogout(link) {
        if (link !== countryCode) {
            const storageName = getSessionStorageItem(AUTH_TOKEN_SESSION_STORAGE_NAME);
            if (!!storageName){
                setIsPopup(true)
                setCountryLink(link)
            } else {
                setIsPopup(false)
                props.history.push('/'+ link +'/home');
                window.location.reload();
            }
        } else {
            setIsLocalesMenu(!isLocalesMenu)
        }
    }

    function toggleLanguage(event) {
      const updatedCountryCode = event.target.checked ? "en_ES" : "es_ES";
      if (authContext?.authToken && storedCountryCode !== updatedCountryCode) {
        localStorage.setItem(
          "lastSelectedLanguageForSpain",
          updatedCountryCode
        );
      }
      setSessionStorageItem(
        COUNTRY_CODE_NAME,
        getFormattedCountryCode(updatedCountryCode)
      );
      props.history.replace({
        pathname: pathname.replace(countryCode, updatedCountryCode),
        search
      });
      setTimeout(() => {
        window.location.reload();
      }, 500);
    }

    return (
        <>
            <div className="menu_top">
                <div className="menu_top_wrapper container">
                    <Link className="logo" to={t("logo").href} titel={t("logo").title} name={t("logo").name}
                          aria-label={t("logo").aria_label}/>

                    <div className="menu_top_language">
                        <img src={t("language").title} alt=""/>
                        <div className="menu_top_language_info ">
                            {   authContext.accountData?.firstName &&
                                <>
                                    <div className="menu_top_language_info-name text-truncate">
                                        {t("greeting", {name: authContext.accountData.firstName})}
                                    </div>
                                    <Logout handleClick={() => logout()}/>
                                </>
                            }
                            {countryCode?.split('_')[1]==='ES' && <div className='menu_top_language_toggle'>
                                <span>español</span>
                                <Toggle
                                    defaultChecked={countryCode === "en_ES"}
                                    icons={false}
                                    onChange={(event) => toggleLanguage(event)}
                                />
                                <span>english</span>
                            </div>}
                            <div onClick={() => setIsLocalesMenu(!isLocalesMenu)}>
                                <img className="flag" src={t("language").flag} alt={t("language.alt")}/>
                            </div>
                        </div>
                        {!!isLocalesMenu && <div className="localization-wrapper">
                                <ul className={"localization-picker"}>
                                    {t("languages").map((country, i) => {
                                        return <li key={i}
                                                   className={"localization-picker-element"}
                                                   onClick={() => handleLogout(country.link)}>
                                            <img className={"flag"} src={country.flag} alt={country.name + "_img"} />
                                            <p className={"country-name"}>{country.name}</p>
                                        </li>
                                    })}
                                </ul>
                            </div>}
                        {!!isPopup && <div className="localization-change-info-wrapper"
                                           onClick={() => setIsPopup(false)}>
                            <div className="localization-change-info">
                                <p>{t("localizationChangeInfo")}</p>
                                <span className="button-wrapper">
                                    <button className="btn-primary" onClick={() => handleCountryChange(!isPopup)}>
                                        {t("okBtn")}
                                    </button>
                                    <button id="discard" className="btn-secondary" onClick={() => {setIsPopup(!isPopup)}}>
                                        {t("discardBtn")}
                                    </button>
                                </span>
                            </div>
                        </div>}
                    </div>
                </div>
            </div>
            <div className="menu" id="menu">
                {/*menu for mobil*/}
                <div className="menu_wrapper  mobil">

                    <div className="container">
                        <div className="menu_left">
                            <div id="nav-icon4" className={state.openMenu ? "menu_burger open" : "menu_burger"}
                                 onClick={() => setState({openMenu: !state.openMenu})}>
                                <span></span>
                                <span></span>
                                <span></span>
                            </div>
                            <Link className="logo" to={t("logo").href} titel={t("logo").title} name={t("logo").name}
                                  aria-label={t("logo").aria_label} />
                        </div>

                        <div className="menu_right">

                            <div className="menu_right_link">
                                <Link className="menu_right_login"
                                      to={t("btn").href}>
                                </Link>
                            </div>
                            <div className="menu_right_link">

                            </div>
                        </div>
                    </div>


                    <div className={state.openMenu ? "menu_links menu_links--open" : "menu_links"}>
                        <ul className="menu_links_list list-unstyled">
                            {
                                t("links").map((link, i) =>
                                    <li key={i} className="menu_links_list_link">
                                        <div className="container">
                                            {
                                                link.linkType === "blank" ?
                                                    <a href={link.href} target="_blank" rel="noopener noreferrer">{link.text}</a> :
                                                    <Link
                                                        to={link.href}>
                                                        {link.text}
                                                    </Link>
                                            }
                                        </div>
                                    </li>
                                )
                            }
                        </ul>
                    </div>

                </div>
                {/*menu for desktop*/}
                <div className="menu_wrapper container desktop">
                    <Link className="logo" to={t("logo").href} titel={t("logo").title} name={t("logo").name}
                          aria-label={t("logo").aria_label} />
                    <ul className="menu_links list-unstyled">
                        {
                            t("links").map((link, i) =>
                                <li key={i} className="menu_links_link">
                                    {
                                        link.linkType === "blank" ?
                                            <a href={link.href} target="_blank" rel="noopener noreferrer">{link.text}</a> :
                                            <Link
                                                to={link.href}>
                                                {link.text}
                                            </Link>
                                    }
                                </li>
                            )
                        }
                        <li className="menu_links_link">
                            <Link className="menu_login"
                                  to={t("btn").href}>
                                {t("btn").text}
                            </Link>
                        </li>
                        <li className="menu_links_link">
                            {/*
                            <Cart countOfPlans={countOfPlans}></Cart>
                            */}
                        </li>
                    </ul>
                </div>
            </div>
        </>
    )
};

export default withRouter(Menu);
