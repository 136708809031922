module.exports = {
    headline: "Checkout",
    summary: {
        confirmation: {
            headline: "CONFIRMATION",
            subheadline: "Selected plan"
        },
    },
    steps: [
        {
            label: "Connection Check",
            href: "/en_ES/home/service_package",
            href_label: "BACK TO SERVICE PACKAGES",
            next_btn: "NEXT STEP",
            continue_btn: "Continue without Aquarea Cloud",
            add_installation_items: {
                add_installation_btn: "Add Aquarea Cloud",
                add_installation_label: "Important information:",
                add_installation_text: 'To be able to offer you the full benefits of this service package, it is necessary that your device is connected to the internet. Simply add our Aquarea Cloud device, CZ-TAW1, to your service package purchase to enable this internet connection.</br></br>When purchased, we will automatically add the device onto your service contract purchase and call you in order to arrange an installation date.</br>',
                add_installation_text_comfort: 'Did you know that connecting your Aquarea unit to the internet can provide extensive benefits, including control of your heat pump system using your smart device, and even remote maintenance and management by a service partner! Simply, add our Aquarea Cloud device, CZ-TAW1, to your service contract purchase!</br></br>When purchased, we will automatically add the device onto your service contract purchase and call you in order to arrange an installation date.</br>',
            },
            change_plan_items: {
                change_plan_changeid: 2,
                change_plan_btn_sameid: "NEXT STEP",
                change_plan_btn: "Change my selection to Comfort",
                change_plan_link: "",
                change_plan_label: "Important information:",
                change_plan_text: 'Unfortunately we cannot offer you this service package at this time because your device cannot be connected to the internet. In order to provide you with the best possible service, we will change your selected service package to Aquarea Service+ Comfort.',
            },
            text: "To provide you with the optimal service, we need to make sure that your devices are connected to the internet.",
            inputList: [
                {
                    label: "Is your unit connected to the internet?",
                    width: "col",
                    name: "internetConnection",
                    startValue: true,
                    hint: {
                        label: "not sure?",
                        text: "test",
                        img: "https://picsum.photos/400/200",
                        img_alt: ""
                    },
                    images: [
                        {path: "/image/checkout/CloudAdapter.jpg", type: "image"}
                    ],
                    inputs: {
                        checkboxes: [
                            {
                                label: "Yes",
                                key: "internetConnected",
                                defaultValue: true,
                                value: true,
                            },
                            {
                                label: "No",
                                key: "internetConnected",
                                defaultValue: false,
                                value: false,
                            }
                        ]
                    }
                },
                {
                    label: "What type of control panel do you have?",
                    width: "col",
                    name: "unitForInternet",
                    startValue: true,
                    hint: {
                        label: "not sure?",
                        text: "",
                        img: "",
                        img_alt: ""
                    },
                    images: [
                        {path: "/image/checkout/NewController.jpg", type: "label"},
                        {path: "/image/checkout/OldController.jpg", type: "label"},
                    ],
                    inputs: {
                        checkboxes: [
                            {
                                label: "Yes",
                                key: "internetReady",
                                defaultValue: false,
                                value: true
                            },
                            {
                                label: "No",
                                key: "internetReady",
                                defaultValue: false,
                                value: false
                            }
                        ]
                    }
                },
                {
                    label: "Is your heat pump younger than 1 year?",
                    width: "col",
                    name: "baseUnitAge",
                    startValue: true,
                    inputs: {
                        checkboxes: [
                            {
                                label: "Yes",
                                key: "baseUnitAge",
                                defaultValue: true,
                                value: true,
                            },
                            {
                                label: "No",
                                key: "baseUnitAge",
                                defaultValue: false,
                                value: false,
                            }
                        ]
                    }
                }
            ]
        },
        {
            label: "Account Information",
            href: "/service_package",
            href_label: "GO BACK",
            next_btn: "NEXT STEP",
            inputList: [
                {
                    headline: "Create new account",
                    login: "SIGN IN",
                    login_path: "",
                    login_label: "Already a user? ",
                    inputs: [
                        [{
                            label: "Email",
                            name: "email",
                            placeholder: "",
                            type: "email",
                            validators: ["REQUIRED", "MIN_LENGTH", "MAX_LENGTH", "EMAIL"],
                            constraints: {minLength: 3, maxLength: 80},
                            width: "col-12 col-lg-6",
                            checkId: "email",
                            validation: "matching_email"
                        },
                            {
                                label: "Repeat Email",
                                name: "repeat-email",
                                placeholder: "Email",
                                type: "email",
                                validators: ["REQUIRED", "COMPARE"],
                                validationMessages: {
                                    COMPARE: "The email must be the same",
                                },
                                constraints: {compareControl: "email"},
                                width: "col-12 col-lg-6",
                                checkId: "email",
                            }],
                        [{
                            label: 'Password <span>Min. 8 characters, numbers and letters</span>',
                            name: "password",
                            placeholder: "",
                            type: "password",
                            validators: ["REQUIRED", "MIN_LENGTH","PASSWORD"],
                            constraints: {minLength: 8},
                            width: "col-12 col-lg-6",
                            checkId: "password",
                        },
                            {
                                label: "Repeat Password",
                                name: "repeat-password",
                                placeholder: "Password",
                                type: "password",
                                validators: ["REQUIRED", "COMPARE"],
                                validationMessages: {
                                    COMPARE: "The password must be the same",
                                },
                                constraints: {compareControl: "password", minLength: 8},
                                width: "col-12 col-lg-6",
                                checkId: "password",
                            }]
                    ]
                },
                {
                    headline: "Account address",
                    inputs: [
                        [{
                            label: "First Name",
                            name: "firstName",
                            placeholder: "",
                            type: "text",
                            validators: ["REQUIRED", "MIN_LENGTH", "MAX_LENGTH"],
                            constraints: {minLength: 2, maxLength: 19},
                            width: "col-12 col-lg-6",
                            validation: "required"
                        },
                            {
                                label: "Last Name",
                                name: "lastName",
                                placeholder: "",
                                validators: ["REQUIRED", "MIN_LENGTH", "MAX_LENGTH"],
                                constraints: {minLength: 2, maxLength: 19},
                                type: "text",
                                width: "col-12 col-lg-6",
                                validation: "required"
                            }],
                        [{
                            label: "Street",
                            name: "street",
                            placeholder: "",
                            type: "text",
                            validators: ["REQUIRED", "MIN_LENGTH", "MAX_LENGTH"],
                            constraints: {minLength: 1, maxLength: 30},
                            width: "col"
                        }],
                        [{
                            label: "Zip Code",
                            name: "zipCode",
                            placeholder: "",
                            validators: ["REQUIRED", "EXACTLY_LENGTH"],
                            constraints: {exactlyLength: 5},
                            type: "text",
                            width: "col-6"
                        },
                            {
                                label: "City",
                                name: "city",
                                placeholder: "",
                                validators: ["REQUIRED", "MIN_LENGTH", "MAX_LENGTH"],
                                constraints: {minLength: 1, maxLength: 35},
                                type: "text",
                                width: "col-6"
                            },
                            {
                                label: "COUNTRY <img src='/image/menu/locales/spain.jpg' style='width: 1.5em'/> <br> <span> THIS SERVICE PACKAGE AND THE CONDITIONS ARE APPLYING TO UNITS INSTALLED IN THE GIVEN COUNTRY. IF YOUR HEAT PUMP IS INSTALLED IN A DIFFERENT COUNTRY, PLEASE SELECT THE COUNTRY AT THE TOP RIGHT OF THE PAGE. THIS SHOWS THE COUNTRIES WHERE AQUAREA SERVICE+ IS AVAILABLE.</span>",
                                name: "country",
                                type: "country",
                                width: "col-12"
                            }
                        ],
                        [{
                            label: "Phone Number",
                            name: "phoneNumber",
                            key: "phoneNumber",
                            placeholder: "",
                            validators: ["REQUIRED", "MIN_LENGTH", "MAX_LENGTH"],
                            constraints: {minLength: 9, maxLength: 17},
                            type: "phone",
                            width: "col",
                            offset:3
                        }]
                    ]
                }, 
                {
                    headline: "Tax Information",
                    inputs: [
                        [{
                            label: "Tax Code Type",
                            name: "taxCodeType",
                            emptyOption: "Select tax code type",
                            options: [],
                            validators: ["REQUIRED"],
                            type: "select",
                            width: "col-12 col-lg-6",
                        },
                            {
                                label: "Tax Code",
                                name: "taxCode",
                                placeholder: "",
                                validators: ["REQUIRED", "EXACT_TAX_CODE_LENGTH"],
                                constraints: { exactlyLength: 9 },
                                maxLength: 9,
                                type: "text",
                                width: "col-12 col-lg-6",
                            }],
                        ]
                },
                {
                    headline: "Invoice",
                    inputs: [
                        [{
                            label: "Would you like to receive a paper invoice ?",
                            name: "sendInvoice",
                            type: "checkbox",
                            width: "col-12 col-lg-6"
                        }]
                    ]
                }
            ],
        },
        {
            label: "Payment Details",
            href: "/service_package",
            href_label: "GO BACK",
            subheadline: "Contract payment model",
            per_month:"per month",
            pay: "pay",
            monthly:"monthly",
            quarterly:"quarterly",
            annually:"annually",
            discount: {
              name: "Discount",
              placeholder: "insert discount code",
              button: "apply",
              remove: "remove",
              invalidHeadline: "The entered discount code is invalid",
              invalidHint: "Please check your input",
              success: "Your discount code was accepted and will be automatically applied to your purchase. Please note that no deduction will be visible from the total payment amount until after the checkout, where you can view this discount within your payment schedule.",
              errors: {
                tooLong: "The entered discount code is too long."
              }
            },
            text: "Please select your payment method Payment will be completed after checkout",
            disclaimer: "When purchasing an \"Aquarea+ Premium\" servie package, you will receive \"Aquarea+ Smart\" until a Panasonic technician checks the installation and approves that the key technical requirements are fulfilled. In the case of not achieveing these technical requirements, you will remain an \"Aquarea+ Smart\" customer and can enjoy the benefits of this package. You will be charged the \"Aquarea+ Smart\" price until the time your installation has been approved for \"Aquarea+ Premium\".",
            credit_secure: "Please note that our platform only accepts credit cards that have been 3D Secure enabled.\n" +
                "3D Secure is most often an extra step in the payment process validation, where the cardholder needs to enter a code that has been sent to their mobile phone as part \n" +
                "of the checkout process. In order to register your credit card for this, please contact your bank or credit card issuer directly,\n" +
                "or check their website for further instructions. Alternatively, you can pay with PayPal.\n",
            visa_secure: "/image/icons/checkout/visaSecure.png",
            visa_secure_alt: "visa secure",
            mastercard_id_check: "/image/icons/checkout/mastercardIdCheckBlack.png",
            mastercard_id_check_alt: "mastercard id check",
            agreement: "I confirm that I have read and agree to the terms of the <a href=\"/en_ES/home/terms_and_conditions\" target=\"_blank\">Aquarea Service+ Terms and Conditions</a>, and I am aware of the <a href=\"/en_ES/home/privacy_policy\" target=\"_blank\">Aquarea Service+ Privacy Notice.</a>",
            policy: "I expressly request and agree that you commence the contracted service before the expiry of the withdrawal period.\n I will be obliged to pay for any Services including repair and maintenance services performed prior to me exercising the 14 day right to withdraw.\n",
            campaign: "I agree to receive a separate email from Treedom with a code to start flattening my tree.",
            next_btn: "NEXT STEP",
            inputList: [
                {
                    headline: "Enter payment details",
                    inputs: [
                        {
                            label: "Payment method",
                            name: "payment_opt",
                            type: "payment_opt",
                            payments: [
                                {
                                    id: "0",
                                    label: "Payment method",
                                    key: "paymentOpt",
                                    value: "credit",
                                    image: "/image/icons/checkout/credit.png",
                                    isChecked: false
                                },
                                {
                                    id: "1",
                                    label: "Payment method",
                                    key: "paymentOpt",
                                    value: "paypal",
                                    image: "/image/icons/checkout/paypal.png",
                                    isChecked: false
                                },
                            ],
                            width: "col"
                        },
                        {
                            label: "CARD NUMBER",
                            name: "card_number",
                            placeholder: "",
                            type: "text",
                            width: "col"
                        },
                        {
                            label: "EXPIRATION DATE",
                            name: "expiration_date",
                            placeholder: "Expiration date",
                            type: "text",
                            width: "col col-lg-6"
                        },
                        {
                            label: "CVV",
                            name: "cvv",
                            placeholder: "",
                            type: "text",
                            width: "col col-lg-6"
                        },
                        {
                            labels: [
                                {
                                    id: "datarights",
                                    label: "Check here to indicate that you have read and agree to the terms of the Aquarea Service+ Customer Agreement",
                                    name: "datarights",
                                    value: "",
                                    width: "col"
                                },
                            ],
                            type: "radio",
                            width: "col",
                        }
                    ]
                },
            ],
        },
        {
            label: "Confirmation",
            href: "/service_package",
            href_label: "GO BACK",
            next_btn: "NEXT STEP",
            inputList: [
                {
                    headline: "Create new account",
                    inputs: [
                        {
                            label: "EMAIL",
                            placeholder: "",
                            type: "text",
                            width: "col col-lg-6"
                        },
                        {
                            label: "PASSWORD",
                            placeholder: "",
                            type: "text",
                            width: "col col-lg-6"
                        }
                    ]
                },
                {
                    headline: "Account address",
                    inputs: [
                        {
                            label: "CUSTOMER TYPE",
                            name: "customer",
                            placeholder: "Private",
                            type: "select",
                            width: "col col-lg-6",
                            options: [
                                "Private",
                                "test1",
                                "test2",
                                "test3",
                                "test4",
                                "test5",
                                "test6"
                            ]
                        },
                        {
                            label: "FIRST NAME",
                            name: "first_name",
                            placeholder: "",
                            type: "text",
                            width: "col col-lg-6"
                        },
                        {
                            label: "Last NAME",
                            name: "last_name",
                            placeholder: "",
                            type: "text",
                            width: "col col-lg-6"
                        },
                        {
                            label: "STREET",
                            name: "street",
                            placeholder: "",
                            type: "text",
                            width: "col"
                        },
                        {
                            label: "ZIP CODE",
                            name: "zipCode",
                            validators: ["REQUIRED", "ZIPCODE"],
                            constraints: {length: 5},
                            placeholder: "",
                            type: "text",
                            width: "col col-lg-6"
                        },
                        {
                            label: "CITY",
                            name: "city",
                            placeholder: "",
                            type: "text",
                            width: "col col-lg-6"
                        },
                        {
                            label: "PHONE NUMBER",
                            name: "phoneNumber",
                            placeholder: "",
                            type: "text",
                            width: "col"
                        }
                    ]
                }
            ],
        }
    ]
}

