module.exports = {
    taxInformation: "Informazioni Fiscali",
    details: {
        title: "Titolo",
        firstName: "Nome",
        lastName: "cognome",
        name: "Nome",
        email: "Email",
        address : "Indirizzo",
        zipCode : "C.A.P.",
        city: "Città (Provincia)",
        phoneNumber: "recapito telefonico",
        phone: "recapito telefonico",
        taxCodeTypeEmptyOption: "Selezionare il tipo di codice fiscale",
        taxCodeType: "Tipo di Codice Fiscale",
        taxCode: "Codice Fiscale",
    },
    btn: {
        edit: "Modificare le informazioni personali",
        back: "Torna al mio account",
        update: "aggiorna l'account",
        reset: "cambia la password"
    },
    editAccount: {
        headline: "Modificare le informazioni personali"
    },
    loading: "Caricamento ..."
}
