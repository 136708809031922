module.exports = {
  headline: "Contratto Aquarea Service+",
  monthly: "mese",
  quarterly: "trimestre",
  annually: "anno",
  period: "periodo",
  table: {
    headers: {
      date: "SCADENZA",
      price: "PAGAMENTO",
      status: "STATO",
    },
  },
  PENDING: "PENDENTE",
  BLOCKED: "BLOCCATO",
  PAID: "PAGATO",
  CANCELLED: "CANCELLATO",
  FAILED: "FALLITO",
  PLANNED: "PIANIFICATO",
  RETRY: "RIPROVA",
  REFUNDED: "RIMBORSATO",
  OPEN: "APRE",
  viewAll: "Se tutti i pagatori",
  REDUCED_FEE:"Discount \"{DISCOUNT_CODE}\" was applied. Amount was deducted from payment scheudule.",
  THREE_MONTHS_FREE: "Discount \"{DISCOUNT_CODE}\" was applied. Amount was deducted from payment scheudule.",
  NO_INSTALLATION_FEE: "Discount \"{DISCOUNT_CODE}\" was applied. Amount was deducted from payment scheudule.",
  UPGRADE_REDUCED_FEE: "Discount \"{DISCOUNT_CODE}\" was applied. Amount was deducted from payment scheudule.",
  UPGRADE_NO_INSTALLATION_FEE: "Discount \"{DISCOUNT_CODE}\" was applied. Amount was deducted from payment scheudule.",
};
