import React, { useContext, useEffect, useState } from "react";
import Footer from "../components/footer";
import { OptionalValue } from "../components/layout/detailEntry";
import {
  Page,
  PageBreadcrumb,
  PageHeader,
  PageHeadline,
} from "../components/layout/page";
import { Section, SectionSubheadline } from "../components/layout/section";
import Menu from "../components/menu";
import AccountInformation from "../components/myaccount/accountInformation";
import Reports from "../components/myaccount/reports";
import { getReports } from "../lib/dataAccess/customer/reportsRestEndpoint";
import Contract from "../components/myaccount/contract";
import InvoiceHistory from "../components/myaccount/invoiceHistory";
import NoServicePackage from "../components/myaccount/noServicePackage";
import YourProtectionPlan from "../components/myaccount/yourProtectionPlan";
import RepairOrder from "../components/myaccount/repairOrder";
import TaxCodeModal from "../components/myaccount/taxCodeModal";
import Loader from "react-loader-spinner";
import { getAccount } from "../lib/dataAccess/accountService";
import i18 from "../lib/i18n";
import {
  AUTH_TOKEN_SESSION_STORAGE_NAME,
  COUNTRY_CODE_NAME,
  getSessionStorageItem,
  removeSessionStorage,
} from "../lib/sessionStorage";
import { getCartFromServer } from "../lib/dataAccess/checkout/cartRestEndpoint";
import { getRepairOrders } from "../lib/dataAccess/checkout/orderRestEndpoint";
import { AuthContext } from "../lib/contexts/authContext";
import { CartContext } from "../lib/contexts/cartContext";
import { TaxCodeContext } from "../lib/contexts/taxCodeContext";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import { BtnGroup, StandardButton } from "../components/layout/buttons";
import { updateTaxCode } from "../lib/dataAccess/customer/accountRestEndpoint";
import { ErrorResponseHandling } from "../lib/dataAccess/errorResponseHandling";
import { BAD_REQUEST_STATUS, UNAUTHORIZED_STATUS } from "../lib/constants";

const t = i18.namespace("MyAccount");

const countryCode = getSessionStorageItem(COUNTRY_CODE_NAME) || "da_DK";
const isTaxCodeRequiredCountry = ["it_it", "es_es", "en_es"].includes(
  countryCode.toLowerCase()
);

const MyAccount = () => {
  const { authContext, setAuthContext } = useContext(AuthContext);
  const { setCartContext } = useContext(CartContext);
  const { taxCodeContext } = useContext(TaxCodeContext);
  const [accountData, setAccountData] = useState(null);
  const [cartData, setCartData] = useState(null);
  const [reports, setReports] = useState(null);
  const [repairOrders, setRepairOrders] = useState(null);
  const [askTaxCode, setAskTaxCode] = useState(false);
  const [isCheckoutTriggered, setIsCheckoutTriggered] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [showErrorMessage, setShowErrorMessage] = useState(false);
  const history = useHistory();

  useEffect(() => {
    fetchAccountData();
    fetchCartData();
    fetchReports();
    fetchRepairOrders();
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (isTaxCodeRequiredCountry && accountData?.enforceTaxCode === true) {
      setAskTaxCode(true);
    } else {
      setAskTaxCode(false);
    }
  }, [accountData]); // eslint-disable-line react-hooks/exhaustive-deps

  const fetchAccountData = async () => {
    await getAccount(getSessionStorageItem(AUTH_TOKEN_SESSION_STORAGE_NAME))
      .then((result) => {
        setAccountData(result?.data);
        setAuthContext({ ...authContext, accountData: result?.data });
      })
      .catch((error) => {
        console.error(error);
        if (error?.response?.status === UNAUTHORIZED_STATUS) {
          removeSessionStorage();
          setAccountData(null);
          setAuthContext({ authToken: null, accountData: null });
          history.push("/" + countryCode + "/home/login");
        }
      });
  };

  const fetchCartData = async () => {
    await getCartFromServer(
      null,
      getSessionStorageItem(AUTH_TOKEN_SESSION_STORAGE_NAME)
    )
      .then((result) => {
        setCartData(result?.data);
        setCartContext(result?.data);
      })
      .catch((error) => {
        console.error(error);
        if (
          error?.response?.status === UNAUTHORIZED_STATUS ||
          error?.response?.status === BAD_REQUEST_STATUS
        ) {
          removeSessionStorage();
          setCartData(null);
          setCartContext({
            id: null,
            anonymousId: null,
            planId: null,
          });
          history.push("/" + countryCode + "/home/login");
        }
      });
  };

  const fetchReports = async () => {
    await getReports(getSessionStorageItem(AUTH_TOKEN_SESSION_STORAGE_NAME))
      .then((result) => {
        setReports(result?.data);
      })
      .catch((error) => {
        console.error(error);
      });
  };

  const fetchRepairOrders = async () => {
    if (!repairOrders) {
      await getRepairOrders(
        getSessionStorageItem(AUTH_TOKEN_SESSION_STORAGE_NAME)
      )
        .then((result) => {
          setRepairOrders(result?.data);
        })
        .catch((error) => {
          console.error(error);
        });
    }
  };

  const redirectToCheckout = () => {
    if (cartData?.type === "UPGRADE") {
      history.push("upgrade/checkout");
    } else {
      history.push("/" + countryCode + "/home/checkout/payment");
    }
  };

  const handleFinishCheckout = () => {
    if (isTaxCodeRequiredCountry && !accountData?.taxCode) {
      setAskTaxCode(true);
      setIsCheckoutTriggered(true);
      return;
    }
    redirectToCheckout();
  };

  const handleUpdateTaxCode = async (taxCodeType, taxCode) => {
    setIsLoading(true);
    setShowErrorMessage(false);
    try {
      let res = await updateTaxCode(
        {
          taxCodeType,
          taxCode,
        },
        getSessionStorageItem(AUTH_TOKEN_SESSION_STORAGE_NAME)
      );
      setIsLoading(false);
      if (res.data === true) {
        setAskTaxCode(false);
        if (isCheckoutTriggered) {
          setIsCheckoutTriggered(false);
          redirectToCheckout();
        } else {
          fetchAccountData();
        }
        return;
      }
      if (res.data === false) {
        setShowErrorMessage(true);
      }
    } catch (error) {
      setIsLoading(false);
      ErrorResponseHandling(error, authContext, setAuthContext, history);
    }
  };

  const drawRepairOrders = () => {
    if (!repairOrders || repairOrders?.length === 0) {
      return null;
    }
    return (
      <section className="section repair-orders">
        {repairOrders.map((order, index) => (
          <RepairOrder
            key={index}
            index={index}
            account={accountData}
            order={order}
            taxCodeTypes={taxCodeContext?.taxCodeTypes || []}
          />
        ))}
      </section>
    );
  };

  const getContracts = () => {
    let contracts = [];
    accountData?.installedBaseUnits?.forEach((baseUnit, index) => {
      if (baseUnit?.orderData?.orderNumber !== undefined) {
        contracts.push(
          <Section key={baseUnit?.id} id={"contract" + index}>
            <YourProtectionPlan account={accountData} baseUnit={baseUnit} />
            <Section id={"contractbaseunit" + index}>
              <Contract baseUnit={baseUnit} />
            </Section>
            <OptionalValue
              condition={reports && reports?.length > 0}
              emptyValue=""
            >
              <Section id="reports">
                <Reports
                  account={accountData}
                  reports={reports}
                  index={index}
                />
              </Section>
            </OptionalValue>
            <OptionalValue
              condition={baseUnit?.orderData !== undefined}
              emptyValue=""
            >
              <Section id={"invoice_history" + index}>
                <SectionSubheadline>{t("invoiceHistory")}</SectionSubheadline>
                <InvoiceHistory
                  orderData={baseUnit?.orderData}
                  orderId={baseUnit?.orderData?.orderId}
                />
              </Section>
            </OptionalValue>
          </Section>
        );
      }
    });
    return contracts;
  };

  if (!accountData || !cartData) {
    return (
      <Loader
        type="TailSpin"
        color="#7099AD"
        height={200}
        width={200}
        className="loader"
      />
    );
  }

  const firstOrderDataIndex = accountData?.installedBaseUnits?.findIndex(unit => unit?.orderData);
  return (
    <Page>
      {isTaxCodeRequiredCountry && (
        <TaxCodeModal
          id={"taxCodeModal"}
          taxCodeTypes={taxCodeContext?.taxCodeTypes || []}
          setAskTaxCode={setAskTaxCode}
          askTaxCode={askTaxCode}
          handleUpdateTaxCode={handleUpdateTaxCode}
          isLoading={isLoading}
          setShowErrorMessage={setShowErrorMessage}
          showErrorMessage={showErrorMessage}
        />
      )}
      <Menu />
      <div className="my-account container">
        <PageHeader>
          <PageBreadcrumb />

          <PageHeadline className="my-account_headline">
            {t("welcome") +
              accountData?.firstName +
              " " +
              accountData?.lastName}
          </PageHeadline>
        </PageHeader>

        <div className="d-flex">
          <aside className="my-account_sidenav sidenav sidenav--sticky">
            <nav className="sidenav_container">
              <ul className="sidenav_nav">
                <OptionalValue
                  condition={accountData?.installedBaseUnits?.some(unit => unit?.orderData)}
                  emptyValue=""
                >
                  <li
                    className="sidenav_nav_item"
                    key={t("yourProtectionPlan")}
                  >
                    <a className="sidenav_nav_link" href={`#contract${firstOrderDataIndex}`}>
                      {t("yourProtectionPlan")}
                    </a>
                  </li>
                  <li className="sidenav_nav_item" key={t("contract")}>
                    <a className="sidenav_nav_link" href={`#contractbaseunit${firstOrderDataIndex}`}>
                      {t("contract")}
                    </a>
                  </li>
                  <li className="sidenav_nav_item" key={t("invoiceHistory")}>
                    <a className="sidenav_nav_link" href={`#invoice_history${firstOrderDataIndex}`}>
                      {t("invoiceHistory")}
                    </a>
                  </li>
                </OptionalValue>
                <li className="sidenav_nav_item" key={t("accountInformation")}>
                  <a className="sidenav_nav_link" href="#account_information">
                    {t("accountInformation")}
                  </a>
                </li>
              </ul>
            </nav>
          </aside>

          <div className="my-account_content">
            <OptionalValue
              condition={cartData?.lineItems?.length > 0}
              emptyValue=""
            >
              <Section id="finish_checkout" key={t("finishCheckout")}>
                <SectionSubheadline>{t("finishCheckout")}</SectionSubheadline>
                <BtnGroup>
                  <StandardButton
                    classNames="btn-primary"
                    isEnabled={true}
                    onClick={handleFinishCheckout}
                    text={
                      cartData?.type === "UPGRADE"
                        ? t("finishUpgrade")
                        : t("gotoCheckout")
                    }
                  />
                </BtnGroup>
              </Section>
            </OptionalValue>
            <OptionalValue
              condition={accountData?.installedBaseUnits?.length > 0}
              emptyValue=""
            >
              {drawRepairOrders()}
              {getContracts()}
            </OptionalValue>
            <OptionalValue
              condition={!(accountData?.installedBaseUnits?.length > 0)}
              emptyValue=""
            >
              <Section id="no_service_package" key={t("noServicePackage")}>
                <SectionSubheadline>{t("noServicePackage")}</SectionSubheadline>
                <NoServicePackage account={accountData} />
              </Section>
            </OptionalValue>
            <Section id="account_information">
              <SectionSubheadline>{t("accountInformation")}</SectionSubheadline>
              <AccountInformation account={accountData} />
            </Section>
          </div>
        </div>
      </div>
      <Footer />
    </Page>
  );
};

export default MyAccount;
