import React, { useContext, useState } from "react";
import { useHistory } from "react-router-dom";
import i18 from "../../lib/i18n";
import { SmallSectionHeadline } from "../layout/section";
import { StandardButton } from "../layout/buttons";
import {
  getSessionStorageItem,
  COUNTRY_CODE_NAME,
  AUTH_TOKEN_SESSION_STORAGE_NAME,
} from "./../../lib/sessionStorage";
import { updateTaxCode } from "../../lib/dataAccess/customer/accountRestEndpoint";
import { ErrorResponseHandling } from "../../lib/dataAccess/errorResponseHandling";
import { AuthContext } from "../../lib/contexts/authContext";
import TaxCodeModal from "./taxCodeModal";

const countryCode = getSessionStorageItem(COUNTRY_CODE_NAME) || "da_DK";
const isTaxCodeRequiredCountry = ["it_it", "es_es", "en_es"].includes(
  countryCode.toLowerCase()
);

const t = i18.namespace("RepairOrder");

const PAID = "paid";
const PENDING = "pending";
const CANCELLED = "cancelled";

const ZERO_INVOICE_AMOUNT = 0;

const RepairOrder = ({ index, account, order, taxCodeTypes }) => {
  const { orderId, orderNumber, totalGross } = order;
  const paymentStatus = order?.paymentStatus.toLowerCase();
  const orderStatus = order?.orderStatus.toLowerCase();
  const invoiceAmount = totalGross?.amount + " " + totalGross?.currency;
  const repairPaymentMethodPath =
    "/" + countryCode + "/home/repair_payment_method/" + orderId;

  const { authContext, setAuthContext } = useContext(AuthContext);
  const [askTaxCode, setAskTaxCode] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [showErrorMessage, setShowErrorMessage] = useState(false);
  const history = useHistory();

  const handleUpdateTaxCode = async (taxCodeType, taxCode) => {
    setIsLoading(true);
    setShowErrorMessage(false);
    try {
      let res = await updateTaxCode(
        {
          taxCodeType,
          taxCode,
        },
        getSessionStorageItem(AUTH_TOKEN_SESSION_STORAGE_NAME)
      );
      setIsLoading(false);
      if (res.data === true) {
        setAskTaxCode(false);
        history.push(repairPaymentMethodPath);
      }
      if (res.data === false) {
        setShowErrorMessage(true);
      }
    } catch (e) {
      setIsLoading(false);
      ErrorResponseHandling(e, authContext, setAuthContext, history);
    }
  };

  const handlePayNow = () => {
    if (isTaxCodeRequiredCountry && !account?.taxCode) {
      setAskTaxCode(true);
    } else {
      history.push(repairPaymentMethodPath);
    }
  };

  return (
    <>
      {isTaxCodeRequiredCountry && (
        <TaxCodeModal
          id={"taxCodeModal"}
          taxCodeTypes={taxCodeTypes}
          setAskTaxCode={setAskTaxCode}
          askTaxCode={askTaxCode}
          handleUpdateTaxCode={handleUpdateTaxCode}
          isLoading={isLoading}
          setShowErrorMessage={setShowErrorMessage}
          showErrorMessage={showErrorMessage}
        />
      )}
      <div className="repair-order" id={"contract-repair-order-" + index}>
        <SmallSectionHeadline>{t("headline")}</SmallSectionHeadline>
        <p>{orderNumber}</p>
        <div className="repair-order-content">
          <div className="text-content">
            <span>{t("thanks")}</span>
            {paymentStatus !== PAID && <span>{t("text")}</span>}
            {paymentStatus === PAID &&
              totalGross.amount !== ZERO_INVOICE_AMOUNT && (
                <span>{t("isPaidText")}</span>
              )}
            {paymentStatus === PAID &&
              totalGross.amount === ZERO_INVOICE_AMOUNT && (
                <span>{t("noInvoiceText")}</span>
              )}
          </div>
          <strong>{t("invoiceAmountText") + invoiceAmount}</strong>
          <div className="btn-container">
            {paymentStatus !== PAID && orderStatus !== CANCELLED && (
              <StandardButton
                text={
                  paymentStatus === PENDING
                    ? t("pendingButtonText")
                    : t("payButton")
                }
                onClick={handlePayNow}
                isEnabled={paymentStatus !== PENDING}
                classNames="btn-quarternary"
              />
            )}
          </div>
        </div>
      </div>
    </>
  );
};

export default RepairOrder;
