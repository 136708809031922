import React, {useEffect, useState} from "react";
import {Redirect, Route, Switch, useHistory} from "react-router-dom";

import RouteChangeTracker from './lib/tracking/RouteChangeTrackerGA';

import {MyAccountEditPage} from "./pages/MyAccountEditPage";
import CancelProtectionPlan from "./pages/CancelProtectionPlan";
import NoMatch404 from "./pages/404";

import CookiePolicy from "./pages/CookiePolicy";

import LandingPage from "./pages/LandingPage";
import HomePage from "./pages/cms/HomePage";
import ProtectionPlans from "./pages/ProtectionPlans";
import Checkout from "./pages/Checkout";
import LoginPage from "./pages/LoginPage";
import MyAccount from "./pages/MyAccount";
import Success from "./pages/Success";
import UpgradeSuccess from "./pages/UpgradeSuccess";
import RegisterUnit from "./pages/RegisterUnit";
import PaymentChangeSuccess from "./pages/PaymentChangeSuccess";
import PasswordReset from "./pages/PasswordReset";

import {ProtectionPlanContext} from "./lib/contexts/protectionPlanContext";
import {TokenContext} from "./lib/contexts/tokenContext";
import {AuthContext} from './lib/contexts/authContext';
import {CartContext} from './lib/contexts/cartContext';
import {ProductContext} from "./lib/contexts/productContext";
import { TaxCodeContext } from "./lib/contexts/taxCodeContext";
import {getCountryForCountryCode, getFormattedCountryCode} from "./lib/helper/localizationHelper"
import {UpgradeContractContext} from "./lib/contexts/upgradeContractContext";
import {InstallationFeeContext} from "./lib/contexts/installationFeeContext";

import PrivateRoute from "./lib/routes/privateRoute";

import i18n from "./lib/i18n";
import {
    ANONYMOUS_ID_SESSION_STORAGE_NAME,
    AUTH_TOKEN_SESSION_STORAGE_NAME,
    CART_ID_SESSION_STORAGE_NAME,
    getSessionStorageItem,
    TOKENS_SESSION_STORAGE_NAME,
    COUNTRY_CODE_NAME,
} from "./lib/sessionStorage";
import {getCartFromServer} from "./lib/dataAccess/checkout/cartRestEndpoint";
import {getAccount} from "./lib/dataAccess/accountService";
import {getProduct, getProductService} from "./lib/dataAccess/product/productRestEndpoint";
import { getTaxCodeTypes } from "./lib/dataAccess/customer/customerRestEndpoint";
import { ErrorResponseHandling } from "./lib/dataAccess/errorResponseHandling";
import UpgradeContract from "./pages/UpgradeContract";
import UpgradeContractPayment from "./pages/UpgradeContractPayment";
import RepairPaymentMethod from "./pages/RepairPaymentMethod";
import UpgradeCheckout from "./pages/UpgradeCheckout";
import PasswordForgot from "./pages/PasswordForgot";
import TermsConditions from "./pages/cms/TermsConditions";
//import CookiePolicy from "./pages/cms/CookiePolicy";
import PrivacyPolicy from "./pages/cms/PrivacyPolicy";
import Paypending from "./pages/Paypending";
import Payfailure from "./pages/Payfailure";
import Paycancel from "./pages/Paycancel";
import Payexpiry from "./pages/Payexpiry";
import Payerror from "./pages/Payerror";
import {getLanguageForCountryCode} from "./lib/helper/localizationHelper"
import FaqPage from "./pages/cms/FaqPage";
import UpdateError from "./pages/UpdateExpError";
import { useGTM } from "./lib/tracking/initGTM";
import RepairPayError from "./pages/RepairPayError";
import RepairPaySuccess from "./pages/RepairPaySuccess";


//sessionCountryCode is set via PATH-Param in menu.js
const sessionCountryCode = getSessionStorageItem(COUNTRY_CODE_NAME);
let language = getLanguageForCountryCode(sessionCountryCode);
if(document.documentElement.lang !== language){
    document.documentElement.lang = language
}
if (sessionCountryCode === "en_ES") {
    language="en_ES"
}
i18n.init(language);


function App() {
    const [protectionPlanContext, setProtectionPlanContext] = useState(0);
    const protectionPlanContextValue = {protectionPlanContext, setProtectionPlanContext};

    const [cartContext, setCartContext] = useState(fillInitialCartContext());
    const cartContextValue = {cartContext, setCartContext};

    const [authContext, setAuthContext] = useState(fillInitialAuthContext());
    const authContextValue = {authContext, setAuthContext};

    const [tokenContext, setTokenContext] = useState(fillInitialTokenContext());
    const tokenContextValue = {tokenContext, setTokenContext};

    const [productContext, setProductContext] = useState(fillInitialProductContext());
    const productContextValue = {productContext, setProductContext};

    const [taxCodeContext, setTaxCodeContext] = useState(null);
    const taxCodeContextValue = { taxCodeContext, setTaxCodeContext };

    const [upgradeContractContext, setUpgradeContractContext] = useState(null);
    const upgradeContractContextValue = {upgradeContractContext, setUpgradeContractContext};

    const [installationFeeContext, setInstallationFeeContext] = useState(null);
    const installationFeeContextValue = {installationFeeContext, setInstallationFeeContext};

    const urlCountryCode =  window.location.pathname.split('/')[1];
    const countryCode = sessionCountryCode || getFormattedCountryCode(urlCountryCode);

    const history = useHistory();
    useGTM(urlCountryCode)
    // set setAuthContext and token if exist
    useEffect(() => {
        const authToken = getSessionStorageItem(AUTH_TOKEN_SESSION_STORAGE_NAME);
        let isCancelled = false;

        setAuthContext({...authContext, authToken});

        if (authToken) {
            getAccount(authToken).then(({data}) => {
                if (!isCancelled) {
                    setAuthContext({...authContext, accountData: data});
                }
            })
        }

        setTokenContext(getSessionStorageItem(TOKENS_SESSION_STORAGE_NAME))

        if (getSessionStorageItem(ANONYMOUS_ID_SESSION_STORAGE_NAME) && getSessionStorageItem(ANONYMOUS_ID_SESSION_STORAGE_NAME) !== "undefined") {
            getCartFromServer(getSessionStorageItem(ANONYMOUS_ID_SESSION_STORAGE_NAME))
                .then(res => {
                    setCartContext(res.data);
                })
                .catch(e => {
                    ErrorResponseHandling(e, authContext, setAuthContext, history);
                })
        }

        getProduct()
            .then(res => {
                setProductContext({plans: res.data?.result});
            })
            .catch(e => {
                console.error(e);
            })

        getProductService()
            .then(res => {
                // console.log(res.data?.result[0])
                let installationProduct = {...res.data?.result[0], totalGross: res.data?.result[0].price};
                setInstallationFeeContext(installationProduct);
            })
            .catch(e => {
                console.error(e);
            })

        if (["it_it", "es_es", "en_es"].includes(countryCode.toLowerCase())) {
          getTaxCodeTypes(getCountryForCountryCode(countryCode))
            .then((res) => {
                setTaxCodeContext({ taxCodeTypes: res.data });
            })
            .catch((e) => {
              console.error(e);
            });
        }

        return () => {
            isCancelled = true;
        };
    }, []) // eslint-disable-line react-hooks/exhaustive-deps

    /*
    * get some tokens from cookie
    * */
    function fillInitialTokenContext() {
        return getSessionStorageItem(AUTH_TOKEN_SESSION_STORAGE_NAME);
    }

    function fillInitialCartContext() {
        return {
            id: getSessionStorageItem(CART_ID_SESSION_STORAGE_NAME),
            anonymousId: getSessionStorageItem(ANONYMOUS_ID_SESSION_STORAGE_NAME),
            planId: 98765
        };
    }

    function fillInitialProductContext() {
        return {plans: null, selectedPlan: "contract-heatpump-gold"}
    }

    function fillInitialAuthContext() {
        return {
            authToken: getSessionStorageItem(AUTH_TOKEN_SESSION_STORAGE_NAME),
            accountData: null
        };
    }
    return (
        <InstallationFeeContext.Provider value={installationFeeContextValue}>
            <ProductContext.Provider value={productContextValue}>
                <TokenContext.Provider value={tokenContextValue}>
                    <ProtectionPlanContext.Provider value={protectionPlanContextValue}>
                        <CartContext.Provider value={cartContextValue}>
                            <AuthContext.Provider value={authContextValue}>
                                <UpgradeContractContext.Provider value={upgradeContractContextValue}>
                                    <TaxCodeContext.Provider value={taxCodeContextValue}>
                                        <Switch>
                                            <Route
                                                exact
                                                path="/"
                                                render={() => {
                                                    return (
                                                        <Redirect to={"/landing_page"}/>
                                                    )
                                                }}
                                            />
                                            <Route
                                                exact
                                                path="/home"
                                                render={() => {
                                                    return (
                                                        <Redirect to={"/landing_page"}/>
                                                    )
                                                }}
                                            />
                                            <Route
                                                exact
                                                path="/home/service_package"
                                                render={() => {
                                                    return (
                                                        <Redirect to={"/landing_page"}/>
                                                    )
                                                }}
                                            />
                                            <Route
                                                exact
                                                path="/home/checkout"
                                                render={() => {
                                                    return (
                                                        <Redirect to={"/landing_page"}/>
                                                    )
                                                }}
                                            />
                                            <Route
                                                exact
                                                path="/home/login"
                                                render={() => {
                                                    return (
                                                        <Redirect to={"/landing_page"}/>
                                                    )
                                                }}
                                            />
                                            <Route
                                                exact
                                                path="/home/upgrade/checkout"
                                                render={() => {
                                                    return (
                                                        <Redirect to={"/landing_page"}/>
                                                    )
                                                }}
                                            />
                                            <Route
                                                exact
                                                path="/home/upgrade/:orderId"
                                                render={(match) => {
                                                    return (
                                                        <Redirect to={"/landing_page"}/>
                                                    )
                                                }}
                                            />
                                            <Route
                                                exact
                                                path="/home/register_unit"
                                                render={() => {
                                                    return (
                                                        <Redirect to={"/"+ countryCode +"/home/register_unit"}/>
                                                    )
                                                }}
                                            />
                                            <Route
                                                exact
                                                path="/home/password_forgot"
                                                render={() => {
                                                    return (
                                                        <Redirect to={"/"+ countryCode +"/home/password_forgot"}/>
                                                    )
                                                }}
                                            />
                                            <Route
                                                exact
                                                path="/home/upgrade/success"
                                                render={() => {
                                                    return (
                                                        <Redirect to={"/"+ countryCode +"/home/upgrade/success"}/>
                                                    )
                                                }}
                                            />
                                            <Route
                                                exact
                                                path="/success"
                                                render={() => {
                                                    return (
                                                        <Redirect to={"/"+ countryCode +"/success"}/>
                                                    )
                                                }}
                                            />
                                            <Route
                                                exact
                                                path="/paypending"
                                                render={() => {
                                                    return (
                                                        <Redirect to={"/"+ countryCode +"/paypending"}/>
                                                    )
                                                }}
                                            />
                                            <Route
                                                exact
                                                path="/payfailure"
                                                render={() => {
                                                    return (
                                                        <Redirect to={"/"+ countryCode +"/payfailure"}/>
                                                    )
                                                }}
                                            />
                                            <Route
                                                exact
                                                path="/paycancel"
                                                render={() => {
                                                    return (
                                                        <Redirect to={"/"+ countryCode +"/paycancel"}/>
                                                    )
                                                }}
                                            />
                                            <Route
                                                exact
                                                path="/payerror"
                                                render={() => {
                                                    return (
                                                        <Redirect to={"/"+ countryCode +"/payerror"}/>
                                                    )
                                                }}
                                            />
                                            <Route
                                                exact
                                                path="/payexpiry"
                                                render={() => {
                                                    return (
                                                        <Redirect to={"/"+ countryCode +"/payexpiry"}/>
                                                    )
                                                }}
                                            />
                                            <Route exact path="/update_cancel" render={() => <Redirect to={"/"+ countryCode +"/update_cancel"}/>}/>
                                            <Route exact path="/update_failure" render={() => <Redirect to={"/"+ countryCode +"/update_failure"}/>}/>
                                            <Route exact path="/update_error" render={() => <Redirect to={"/"+ countryCode +"/update_error"}/>}/>
                                            <Route exact path="/update_expired" render={() => <Redirect to={"/"+ countryCode +"/update_expired"}/>}/>
                                            <Route exact path="/update_pending" render={() => <Redirect to={"/"+ countryCode +"/update_pending"}/>}/>
                                            <Route exact path="/repair_paycancel/" render={() => <Redirect to={"/"+ countryCode +"/repair_paycancel"}/>}/>
                                            <Route exact path="/repair_payfailure/" render={() => <Redirect to={"/"+ countryCode +"/repair_payfailure"}/>}/>
                                            <Route exact path="/repair_payerror/" render={() => <Redirect to={"/"+ countryCode +"/repair_payerror"}/>}/>
                                            <Route exact path="/repair_payexpiry/" render={() => <Redirect to={"/"+ countryCode +"/repair_payexpiry"}/>}/>
                                            <Route exact path="/repair_paypending/" render={() => <Redirect to={"/"+ countryCode +"/repair_paypending"}/>}/>
                                            <Route
                                                exact
                                                path="/404"
                                                render={() => {
                                                    return (
                                                        <Redirect to={"/"+ countryCode +"/404"}/>
                                                    )
                                                }}
                                            />
                                            <Route
                                                exact
                                                path="/home/password_reset/:target"
                                                render={(match) => {
                                                    return (
                                                        <Redirect to={"/"+ countryCode +"/home/password_reset/"+ match.match.params.target}/>
                                                    )
                                                }}
                                            />
                                            <Route
                                                exact
                                                path="/cookie-policy"
                                                render={() => {
                                                    return (
                                                        <Redirect to={"/"+ countryCode +"/home/cookies"}/>
                                                    )
                                                }}
                                            />
                                            <Route
                                                exact
                                                path="/:lang/cookie-policy"
                                                render={() => {
                                                    return (
                                                        <Redirect to={"/"+ countryCode +"/home/cookies"}/>
                                                    )
                                                }}
                                            />
                                            <Route
                                                exact
                                                path="/privacy-policy"
                                                render={() => {
                                                    return (
                                                        <Redirect to={"/"+ countryCode +"/home/privacy_policy"}/>
                                                    )
                                                }}
                                            />
                                            <Route
                                                exact
                                                path="/:lang/privacy-policy"
                                                render={() => {
                                                    return (
                                                        <Redirect to={"/"+ countryCode +"/home/privacy_policy"}/>
                                                    )
                                                }}
                                            />
                                            <Route exact path="/landing_page" component={LandingPage}/>
                                            <Route exact path="/:countryCode/home" component={HomePage}/>
                                            <Route exact path="/:countryCode/home/service_package" component={() => <ProtectionPlans/>}/>
                                            <Route exact path="/:countryCode/home/checkout" component={Checkout}/>
                                            <Route exact path="/:countryCode/home/checkout/:startStep" component={Checkout}/>
                                            <Route exact path="/:countryCode/home/login" component={LoginPage}/>
                                            <Route exact path="/:countryCode/home/password_reset/:target?" component={PasswordReset}/>
                                            <Route exact path="/:countryCode/home/upgrade/checkout" component={UpgradeCheckout}/>
                                            <Route exact path="/:countryCode/home/upgrade/:orderId" component={UpgradeContract}/>
                                            <Route exact path="/:countryCode/home/upgrade_success" component={UpgradeSuccess}/>
                                            <Route exact path="/:countryCode/home/register_unit" component={RegisterUnit}/>
                                            <Route exact path="/:countryCode/home/updatepayment/:orderId" component={UpgradeContractPayment}/>
                                            <Route exact path="/:countryCode/home/repair_payment_method/:orderId" component={RepairPaymentMethod}/>
                                            <Route exact path="/:countryCode/home/password_forgot" component={PasswordForgot}/>
                                            <PrivateRoute
                                                auth={authContext}
                                                path="/:countryCode/home/my_account/cancel_protection_plans"
                                                component={CancelProtectionPlan}
                                            />
                                            <PrivateRoute
                                                auth={authContext}
                                                path="/:countryCode/home/my_account/updateOrder/:target?"
                                                component={MyAccount}
                                            />
                                            <PrivateRoute
                                                auth={authContext}
                                                path="/:countryCode/home/my_account/edit"
                                                component={MyAccountEditPage}
                                            />
                                            <PrivateRoute
                                                auth={authContext}
                                                path="/:countryCode/home/my_account"
                                                component={MyAccount}
                                            />

                                            <Route path="/:countryCode/home/terms_and_conditions" component={() => <TermsConditions link="/home/terms_and_conditions"/>}/>
                                            <Route path="/:countryCode/home/right_of_withdrawal" component={() => <TermsConditions link="/home/right_of_withdrawal"/>}/>
                                            <Route path="/:countryCode/home/terms_of_use_of_the_website" component={() => <TermsConditions link="/home/terms_of_use_of_the_website"/>}/>
                                            <Route path="/:countryCode/home/privacy_policy" component={PrivacyPolicy}/>
                                            <Route path="/:countryCode/home/cookies" component={CookiePolicy}/>
                                            <Route path="/:countryCode/home/paymentchangesuccess" component={PaymentChangeSuccess}/>
                                            <Route path="/:countryCode/home/faq" component={FaqPage}/>
                                            <Route path="/:countryCode/success" component={Success}/>
                                            <Route path="/:countryCode/paypending" component={Paypending}/>
                                            <Route path="/:countryCode/payfailure" component={Payfailure}/>
                                            <Route path="/:countryCode/paycancel" component={Paycancel}/>
                                            <Route path="/:countryCode/payexpiry" component={Payexpiry}/>
                                            <Route path="/:countryCode/payerror" component={Payerror}/>
                                            <Route path="/:countryCode/update_cancel" component={UpdateError}/>
                                            <Route path="/:countryCode/update_failure/:orderId" component={UpdateError}/>
                                            <Route path="/:countryCode/update_failure" component={UpdateError} />
                                            <Route path="/:countryCode/update_error" component={UpdateError}/>
                                            <Route path="/:countryCode/update_expired" component={UpdateError}/>
                                            <Route path="/:countryCode/update_pending" component={UpdateError}/>
                                            <Route path="/:countryCode/repair_paysuccess/:orderId" component={RepairPaySuccess}/>
                                            <Route path="/:countryCode/repair_paycancel" component={RepairPayError}/>
                                            <Route path="/:countryCode/repair_payfailure/:orderId" component={RepairPayError}/>
                                            <Route path="/:countryCode/repair_payfailure" component={RepairPayError}/>
                                            <Route path="/:countryCode/repair_payerror/:orderId" component={RepairPayError}/>
                                            <Route path="/:countryCode/repair_payerror" component={RepairPayError}/>
                                            <Route path="/:countryCode/repair_payexpiry" component={RepairPayError}/>
                                            <Route path="/:countryCode/repair_paypending" component={RepairPayError}/>
                                            <Route path="/:countryCode/404" component={NoMatch404}/>
                                            <Route render={() => {
                                                return (
                                                    <Redirect to={"/"+ countryCode +"/404"}/>
                                                )
                                            }}/>
                                        </Switch>
                                        <RouteChangeTracker/>
                                    </TaxCodeContext.Provider>
                                </UpgradeContractContext.Provider>
                            </AuthContext.Provider>
                        </CartContext.Provider>
                    </ProtectionPlanContext.Provider>
                </TokenContext.Provider>
            </ProductContext.Provider>
        </InstallationFeeContext.Provider>
    );
}

export default App;
