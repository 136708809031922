import React from "react";
import i18n from "../../lib/i18n";
import { Link } from "react-router-dom";
import {
  COUNTRY_CODE_NAME,
  getSessionStorageItem,
} from "../../lib/sessionStorage";
import { DetailList, DetailListRow, DetailEntry } from "../layout/detailEntry";
import { SectionSubheadline } from "../layout/section";

const t = i18n.namespace("MyAccountAccountInformation");
const countryCode = getSessionStorageItem(COUNTRY_CODE_NAME) || 'da_DK';

const AccountInformation = ({ account }) => {
    return (
      <>
        <DetailList>
          <DetailListRow>
            <DetailEntry label={t("details.name")}>
              {account?.firstName} {account?.lastName}
            </DetailEntry>
            <DetailEntry label={t("details.address")}>
              {account?.addresses[0]?.street}
              <br />
              {account?.addresses[0]?.zipCode} {account?.addresses[0]?.city}
            </DetailEntry>
          </DetailListRow>
          <DetailListRow>
            <DetailEntry label={t("details.email")}>
              {account?.email}
            </DetailEntry>
            <DetailEntry label={t("details.phone")}>
              {account?.addresses[0]?.phoneNumber}
            </DetailEntry>
          </DetailListRow>
        </DetailList>

        {account?.taxCodeType && account?.taxCode && (
          <>
            <SectionSubheadline>{t("taxInformation")}</SectionSubheadline>
            <DetailList>
              <DetailListRow>
                <DetailEntry label={t("details.taxCodeType")}>
                  {account?.taxCodeType}
                </DetailEntry>
                <DetailEntry label={t("details.taxCode")}>
                  {account?.taxCode}
                </DetailEntry>
              </DetailListRow>
            </DetailList>
          </>
        )}

        <div className="mb-3 mb-sm-0 text-center">
          <Link className="btn-secondary" to={"/"+ countryCode +"/home/my_account/edit"}>
            {t("btn.edit")}
          </Link>
          <Link className="btn-primary mx-2" to={"/"+ countryCode +"/home/password_reset"}>
            {t("btn.reset")}
          </Link>
        </div>
      </>
    );
  }

export default AccountInformation;
