module.exports = {
    headline: "Cassa",
    summary: {
        confirmation: {
            headline: "Konfirmation",
            subheadline: "Seleziona il pacchetto"
        },
    },
    steps: [
        {
            label: "Controllare la connessione",
            href: "/it_IT/home/service_package",
            href_label: "Torna ai pacchetti di servizi",
            next_btn: "Prossimo",
            continue_btn: "Procedere senza servizio di installazione",
            add_installation_items: {
                add_installation_btn: "Aggiungi il servizio di installazione a",
                add_installation_label: "Informazioni importanti:",
                add_installation_text: "Per ottenere il massimo beneficio dai nostri pacchetti di servizi, il tuo dispositivo deve essere collegato a Internet. <br><br>Per assicurarti che il tuo dispositivo sia connesso a Internet, ti consigliamo di ordinare il nostro pacchetto di servizi di installazione. Questo servizio è pagato con un ##price## una tantum<br><br>Aggiungiamo automaticamente il pacchetto di servizi di installazione al tuo processo di acquisto. Vi chiameremo per fissare un orario per l'installazione.",
                add_installation_text_comfort: "Per ottenere il massimo beneficio dai nostri pacchetti di servizi, il tuo dispositivo deve essere collegato a Internet. <br><br>Per assicurarti che il tuo dispositivo sia connesso a Internet, ti consigliamo di ordinare il nostro pacchetto di servizi di installazione. Questo servizio è pagato con un ##price## una tantum<br><br>Aggiungiamo automaticamente il pacchetto di servizi di installazione al tuo processo di acquisto. Vi chiameremo per fissare un orario per l'installazione.",
            },
            change_plan_items: {
                change_plan_changeid: 2,
                change_plan_btn_sameid: "Prossimo",
                change_plan_btn: "Cambia la mia scelta in Comfort",
                change_plan_link: "",
                change_plan_label: "Informazioni importanti:",
                change_plan_text: 'Poiché il tuo dispositivo non è collegato a Internet, non possiamo offrirti tutti i nostri pacchetti di servizi in questo momento. Per offrirle il miglior servizio possibile, cambieremo il pacchetto di servizi da lei scelto in Aquarea Service+ Comfort.',
            },
            text: "Per offrirti il miglior servizio, ci assicureremo che i tuoi dispositivi siano collegati a Internet.",
            inputList: [
                {
                    label: "Il tuo sistema Aquarea è collegato a Internet con il dispositivo in figura?​",
                    width: "col",
                    name: "internetConnection",
                    startValue: true,
                    hint: {
                        label: "non è sicuro?",
                        text: "prova",
                        img: "https://picsum.photos/400/200",
                        img_alt: ""
                    },
                    images: [
                        {path: "/image/checkout/CloudAdapter.jpg", type: "image"}
                    ],
                    inputs: {
                        checkboxes: [
                            {
                                label: "Sì",
                                key: "internetConnected",
                                defaultValue: true,
                                value: true,
                            },
                            {
                                label: "No",
                                key: "internetConnected",
                                defaultValue: false,
                                value: false,
                            }
                        ]
                    }
                },
                {
                    label: "Che tipo di telecomando è a corredo della macchina?",
                    width: "col",
                    name: "unitForInternet",
                    startValue: true,
                    hint: {
                        label: "non è sicuro?",
                        text: "",
                        img: "",
                        img_alt: ""
                    },
                    images: [
                        {path: "/image/checkout/NewController.jpg", type: "label"},
                        {path: "/image/checkout/OldController.jpg", type: "label"},
                    ],
                    inputs: {
                        checkboxes: [
                            {
                                label: "Sì",
                                key: "internetReady",
                                defaultValue: false,
                                value: true
                            },
                            {
                                label: "No",
                                key: "internetReady",
                                defaultValue: false,
                                value: false
                            }
                        ]
                    }
                },
                {
                    label: "La tua pompa di calore ha meno di 5 anni?",
                    width: "col",
                    name: "baseUnitAge",
                    startValue: true,
                    inputs: {
                        checkboxes: [
                            {
                                label: "Sì",
                                key: "baseUnitAge",
                                defaultValue: true,
                                value: true,
                            },
                            {
                                label: "No",
                                key: "baseUnitAge",
                                defaultValue: false,
                                value: false,
                            }
                        ]
                    }
                }
            ],
        },
        {
            label: "Informazioni sul conto",
            href: "/it_IT/home/service_package",
            href_label: "Indietro",
            next_btn: "Prossimo",
            inputList: [
                {
                    headline: "Creare un nuovo account",
                    login: "ACCEDI",
                    login_path: "",
                    login_label: "Utente esistente?",
                    inputs: [
                        [{
                            label: "E-mail",
                            name: "email",
                            placeholder: "",
                            type: "email",
                            validators: ["REQUIRED", "MIN_LENGTH", "MAX_LENGTH", "EMAIL"],
                            constraints: {minLength: 3, maxLength: 80},
                            width: "col-12 col-lg-6",
                            checkId: "email",
                            validation: "matching_email"
                        },
                            {
                                label: "Ripetere l'e-mail",
                                name: "repeat-email",
                                placeholder: "Email",
                                type: "email",
                                validators: ["REQUIRED", "COMPARE"],
                                validationMessages: {
                                    COMPARE: "L'e-mail deve essere la stessa",
                                },
                                constraints: {compareControl: "email"},
                                width: "col-12 col-lg-6",
                                checkId: "e-mail",
                            }],
                        [{
                            label: 'Password <span>Minimo 8 caratteri, numeri e lettere</span>',
                            name: "password",
                            placeholder: "",
                            type: "password",
                            validators: ["REQUIRED", "MIN_LENGTH","PASSWORD"],
                            constraints: {minLength: 8},
                            width: "col-12 col-lg-6",
                            checkId: "password",
                        },
                            {
                                label: "Ripetere la password",
                                name: "repeat-password",
                                placeholder: "Password",
                                type: "password",
                                validators: ["REQUIRED", "COMPARE"],
                                validationMessages: {
                                    COMPARE: "La password deve essere la stessa",
                                },
                                constraints: {compareControl: "password", minLength: 8},
                                width: "col-12 col-lg-6",
                                checkId: "password",
                            }]
                    ]
                },
                {
                    headline: "Dati dell'Utente",
                    inputs: [
                        // [{
                        //     label: "Titolo",
                        //     name: "titolo",
                        //     placeholder: "Signora",
                        //     type: "selezionare",
                        //     validators: ["REQUIRED"],
                        //     width: "col-12 col-lg-6",
                        //     emptyOption: "--",
                        //     options: [
                        //         "Mrs",
                        //         "Mr",
                        //         "Mx"
                        //     ]
                        // }],
                        [{
                            label: "Nome",
                            name: "firstName",
                            placeholder: "",
                            type: "text",
                            validators: ["REQUIRED", "MIN_LENGTH", "MAX_LENGTH"],
                            constraints: {minLength: 2, maxLength: 19},
                            width: "col-12 col-lg-6",
                            validation: "required"
                        },
                            {
                                label: "cognome",
                                name: "lastName",
                                placeholder: "",
                                validators: ["REQUIRED", "MIN_LENGTH", "MAX_LENGTH"],
                                constraints: {minLength: 2, maxLength: 19},
                                type: "text",
                                width: "col-12 col-lg-6",
                                validation: "required"
                            }],
                        [{
                            label: "Indirizzo",
                            name: "street",
                            placeholder: "",
                            type: "text",
                            validators: ["REQUIRED", "MIN_LENGTH", "MAX_LENGTH"],
                            constraints: {minLength: 1, maxLength: 30},
                            width: "col"
                        }],
                        [{
                            label: "C.A.P.",
                            name: "zipCode",
                            validators: ["REQUIRED", "EXACTLY_LENGTH"],
                            constraints: {exactlyLength: 5},
                            placeholder: "",
                            type: "text",
                            width: "col-6"
                        },
                            {
                                label: "Città (Provincia)",
                                name: "city",
                                placeholder: "",
                                validators: ["REQUIRED", "MIN_LENGTH", "MAX_LENGTH"],
                                constraints: {minLength: 1, maxLength: 35},
                                type: "text",
                                width: "col-6"
                            },
                            {
                                label: "PAESE <img src='/image/menu/locales/italy.jpg' style='width: 1.5em'/> <br> <span> Il presente pacchetto di servizi e le condizioni si applicano alle unità installate nel paese indicato. Se la pompa di calore è installata in un paese diverso, selezionare il paese in alto a destra della pagina, che mostra i paesi in cui è disponibile il servizio Aquarea Service+.</span>",
                                name: "country",
                                type: "country",
                                width: "col-12"
                            }],
                        [{
                            label: "Numero di telefono",
                            name: "phoneNumber",
                            key: "phoneNumber",
                            placeholder: "",
                            validators: ["REQUIRED", "MIN_LENGTH", "MAX_LENGTH"],
                            constraints: {minLength: 9, maxLength: 17},
                            type: "phone",
                            width: "col",
                            offset:3
                        }]
                    ]
                },
                {
                    headline: "Informazioni Fiscali",
                    inputs: [
                        [{
                            label: "Tipo di Codice Fiscale",
                            name: "taxCodeType",
                            emptyOption: "Selezionare il tipo di codice fiscale",
                            options: [],
                            validators: ["REQUIRED"],
                            type: "select",
                            width: "col-12 col-lg-6",
                        },
                            {
                                label: "Codice Fiscale",
                                name: "taxCode",
                                placeholder: "",
                                validators: ["REQUIRED", "EXACT_TAX_CODE_LENGTH"],
                                constraints: { exactlyLength: 16 },
                                maxLength: 16,
                                type: "text",
                                width: "col-12 col-lg-6",
                            }],
                        ]
                },
            ],
        },
        {
            label: "Informazioni sul pagamento",
            href: "/it_IT/home/service_package",
            href_label: "Indietro",
            subheadline: "Modello di pagamento del contratto",
            per_month:"al mese (IVA inclusa)",
            pay: "pagare",
            monthly:"mensile",
            quarterly:"trimestralmente",
            annually:"annualmente",
            discount: {
                name: "Promozione",
                placeholder: "inserire codice sconto",
                button: "applicare",
                remove: "rimuovere",
                invalidHeadline: "Il codice inserito non è valido",
                invalidHint: "Per favore, controlla il tuo input",
                success: "Il codice sconto è stato accettato e verrà applicato automaticamente all’acquisto. Si prega di notare che nessuna detrazione sarà visibile dall'importo totale del pagamento fino a dopo il checkout, sarà possibile visualizzare questo sconto all'interno del programma di pagamento.",
                errors: {
                    tooLong: "Il codice di sconto inserito è troppo lungo."
                }
            },
            text: "Si prega di selezionare il metodo di pagamento. Il pagamento sarà elaborato dopo il checkout.",
            disclaimer: "Quando si acquista il pacchetto di servizi \"Aquarea + Premium\", si riceve \"Aquarea + Smart\" finché il tecnico Panasonic non verifica l'installazione e approva che siano stati soddisfatti importanti requisiti tecnici. Se non soddisfa questi requisiti tecnici, rimarrà e godrà dei vantaggi del pacchetto \"Aquarea + Smart\". Finché la sua installazione non sarà approvata per \"Aquarea + Premium\", le verrà addebitato il prezzo di \"Aquarea + Smart\".",
            credit_secure: "Ti preghiamo di notare che la nostra piattaforma accetta solo carte di credito abilitate al 3D Secure.\n" +
                "Il 3D Secure è un passaggio aggiuntivo nella convalida del processo di pagamento, in cui il titolare della carta deve inserire un codice che è stato\n" +
                " inviato al proprio telefono cellulare.\n" +
                "Per abilitare la tua carta di credito a questa funzione, devi contattare direttamente la tua banca o l'emittente della tua carta di credito\n" +
                "oppure consultare il loro sito web per ulteriori istruzioni. In alternativa, puoi tranquillamente pagare con PayPal.\n",
            visa_secure: "/image/icons/checkout/visaSecure.png",
            visa_secure_alt: "visa secure",
            mastercard_id_check: "/image/icons/checkout/mastercardIdCheckBlack.png",
            mastercard_id_check_alt: "mastercard id check",
            agreement: "Confermo di aver letto e accettato le <a href=\"/it_IT/home/terms_and_conditions\" target=\"_blank\">Informazioni Precontrattuali per i consumatori e Termini e Condizioni del Servizio Panasonic Aquarea Service+</a>, e sono a conoscenza della <a href=\"/it_IT/home/privacy_policy\" target=\"_blank\">Privacy Policy di Aquarea Service+.</a>",
            policy: "Richiedo espressamente che Panasonic inizi immediatamente la prestazione del Servizio prima che decorra il termine per il Diritto di Recesso. Sono consapevole di dover pagare qualsiasi attività di manutenzione o riparazione eseguita per me durante i 14 giorni in cui posso esercitare il Diritto di Recesso.\n",
            campaign: "Accetto di ricevere una mail separata da Treedom con un codice per iniziare la piantagione del mio albero.",
            next_btn: "Prossimo",
            inputList: [
                {
                    headline: "Inserisci i dettagli del pagamento",
                    inputs: [
                        {
                            label: "Metodo di pagamento",
                            name: "payment_opt",
                            type: "payment_opt",
                            payments: [
                                {
                                    id: "0",
                                    label: "Metodo di pagamento",
                                    key: "paymentOpt",
                                    value: "credito",
                                    image: "/image/icons/checkout/credit.png",
                                    isChecked: false
                                },
                                {
                                    id: "1",
                                    label: "Metodo di pagamento",
                                    key: "paymentOpt",
                                    value: "paypal",
                                    image: "/image/icons/checkout/paypal.png",
                                    isChecked: false
                                },
                            ],
                            width: "col"
                        },
                        {
                            label: "NUMERO DI CARTA",
                            name: "card_number",
                            placeholder: "",
                            type: "text",
                            width: "col"
                        },
                        {
                            label: "DATA DI SCADENZA",
                            name: "expiration_date",
                            placeholder: "Data di scadenza",
                            type: "text",
                            width: "col col-lg-6"
                        },
                        {
                            label: "CVV",
                            name: "cvv",
                            placeholder: "",
                            type: "text",
                            width: "col col-lg-6"
                        },
                        {
                            labels: [
                                {
                                    id: "datarights",
                                    label: "Confermi di aver letto e accettato i termini del Contratto Cliente Aquarea Service+.",
                                    name: "datarights",
                                    value: "",
                                    width: "col"
                                },
                            ],
                            type: "radio",
                            width: "col",
                        }
                    ]
                },
            ],
        },
        {
            label: "Conferma",
            href: "/protection_plans",
            href_label: "Indietro",
            next_btn: "Prossimo",
            inputList: [
                {
                    headline: "Creare un nuovo account",
                    inputs: [
                        {
                            label: "EMAIL",
                            placeholder: "",
                            type: "text",
                            width: "col col-lg-6"
                        },
                        {
                            label: "PASSWORD",
                            placeholder: "",
                            type: "text",
                            width: "col col-lg-6"
                        }
                    ]
                },
                {
                    headline: "Dati dell'Utente",
                    inputs: [
                        {
                            label: "TIPO DI CLIENTE",
                            name: "customer",
                            placeholder: "Privato",
                            type: "select",
                            width: "col col-lg-6",
                            options: [
                                "Private",
                                "test1",
                                "test2",
                                "test3",
                                "test4",
                                "test5",
                                "test6"
                            ]
                        },
                        {
                            label: "FORNAVN",
                            name: "first_name",
                            placeholder: "",
                            type: "text",
                            width: "col col-lg-6"
                        },
                        {
                            label: "EFTERNAVN",
                            name: "last_name",
                            placeholder: "",
                            type: "text",
                            width: "col col-lg-6"
                        },
                        {
                            label: "INDIRIZZO",
                            name: "street",
                            placeholder: "",
                            type: "text",
                            width: "col"
                        },
                        {
                            label: "POSTNUMBER",
                            name: "zipCode",
                            placeholder: "",
                            type: "text",
                            width: "col col-lg-6"
                        },
                        {
                            label: "Città (Provincia)",
                            name: "city",
                            placeholder: "",
                            type: "text",
                            width: "col col-lg-6"
                        },
                        {
                            label: "TELEFONO",
                            name: "phoneNumber",
                            placeholder: "",
                            type: "text",
                            width: "col"
                        }
                    ]
                }
            ],
        }
    ]
}

