    module.exports = {
    headline: "Orden de reparación",
    thanks: "Gracias por ponerse en contacto con Aquarea+ Service!",
    text: "Su orden de reparación ha sido finalizada con éxito. Una vez completado el pago, encontrará su factura en la bandeja de entrada de su correo electrónico.",
    invoiceAmountText: "Importe de la factura: ",
    isPaidText: "Su orden de reparación ha sido finalizada con éxito, y su factura ha sido procesada con éxito.",
    noInvoiceText: "Su pedido de reparación se ha finalizado con éxito y su factura se ha procesado correctamente.",
    payButton: "Pagar ahora",
    pendingButtonText: "En Curso",
};
