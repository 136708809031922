module.exports = {
    headline: "Contrato Aquarea Service+",
    baseUnit: "Dispositivo instalado",
    contract: "Contrato",
    month:"mes",
    monthly:"mes",
    quarterly:"trimestre",
    annually:"año",
    disclaimerHeadline :"Servicio Premium Aquarea+: pendiente de revisión técnica y de funcionamiento",
    disclaimer:"Al adquirir el plan de mantenimiento \"Aquarea Service+ Premium\", recibirás el plan \"Aquarea Service+ Smart\" hasta que el técnico de Panasonic valide el cumplimiento de los requisitos técnicos de tu solicitud. Si no se cumplen estos requisitos técnicos, disfrutarás de las ventajas del plan \"Aquarea Service+  Smart\" . Mientras que tu instalación no sea aprobada para el plan \"Aquarea Service+ Premium\", se te seguirá cobrando el precio de \"Aquarea Service+ Smart\".",
    contract_complete: "Este contrato ya se ha cumplido.",
    contract_cancelled: "Este contrato ha sido cancelado.",
    waitingForDateUpdateStatus:"Esperando respuesta del sistema de servicios de pago",
    updateExpiryDate:'Fecha de caducidad debe renovarse',
    updateFailed:'Error en la actualización de la fecha de caducidad - Vuelva a intentarlo más tarde',
    updateSuccess:'Exp.Date updated',
    details: {
        model: "modelo",
        orderNumber: "Número de pedido",
        installationDate: "fecha de instalación",
        protectionPlan: "plan de mantenimiento",
        location: "dirección",
        serialNumber: "número de serie",
        contractValidTill: "el contrato es válido hasta",
        costs: "costes",
        creditCardNumber:"número de tarjeta de crédito",
        paymentMethod:"método de pago",
        expiryDate:'Fecha de expiración',
        expiryValue:'VÁLIDO HASTA EL'
    },
    btn:{
        upgrade: "Cambiar plan de mantenimiento",
        cancel: "Cancelar plan de mantenimiento",
        payment_update: "cambiar la forma de pago",
        registerHeatpump: "registro de la bomba de calor",
        registerYourDevice: "registre su dispositivo",
        expiry_update:'ACTUALIZAR LA FECHA DE CADUCIDAD',
        updateAllContracts:'Sí Actualización',
        cancelUpdate:'No',
    },
    modal:{
        updateContractsTitle:"Actualizar mi(s) Contrato(s)",
        updateContractsBody:"¿Desea aplicar la actualización de la fecha de vencimiento a todos su(s) contrato(s)?",
    },
    accountPage:'/es_ES/home/my_account'
}
