module.exports = {
  headline: "Aquarea Service+ Contract",
  monthly: "month",
  quarterly: "quarter",
  annually: "year",
  period: "period",
  table: {
    headers: {
      date: "DATE",
      price: "AMOUNT",
      status: "STATUS",
    },
  },
  PENDING: "PENDING",
  BLOCKED: "BLOCKED",
  PAID: "PAID",
  CANCELLED: "CANCELLED",
  FAILED: "FAILED",
  PLANNED: "PLANNED",
  RETRY: "RETRY",
  REFUNDED: "REFUNDED",
  OPEN: "OPEN",
  viewAll: "View all Invoices",
  REDUCED_FEE:"Discount \"{DISCOUNT_CODE}\" was applied. Amount was deducted from payment scheudule.",
  THREE_MONTHS_FREE: "Discount \"{DISCOUNT_CODE}\" was applied. Amount was deducted from payment scheudule.",
  NO_INSTALLATION_FEE: "Discount \"{DISCOUNT_CODE}\" was applied. Amount was deducted from payment scheudule.",
  UPGRADE_REDUCED_FEE: "Discount \"{DISCOUNT_CODE}\" was applied. Amount was deducted from payment scheudule.",
  UPGRADE_NO_INSTALLATION_FEE: "Discount \"{DISCOUNT_CODE}\" was applied. Amount was deducted from payment scheudule.",
};
