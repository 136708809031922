module.exports = {
    headline: "Contratto Aquarea Service+",
    baseUnit: "Dispositivo installato",
    contract: "Contratto",
    month:"mese",
    monthly:"mese",
    quarterly:"trimestre",
    annually:"anno",
    disclaimerHeadline :"Aquarea+ Premium Service: in attesa di un controllo tecnico di funzionalità della connessione al Cloud.",
    disclaimer:"Per attivare i pacchetti \"Premium\" e \"Smart\", è necessario una linea internet attiva e il collegamento dell’impianto all'Aquarea Service Cloud. \n" +
        "Per il pacchetto \"Premium\", in fase di sottoscrizione del contratto, viene acquistata inizialmente la versione \"Aquarea+ Smart\" al corrispondente valore di vendita.\n" +
        "Una volta verificata e comunicata la piena idoneità dell’impianto, il pacchetto passerà alla versione \"Premium\" e verrà addebitata la restante quota a copertura del valore di vendita del pacchetto \"Premium\".\n" +
        "In assenza di requisiti idonei al completo utilizzo del pacchetto \"Aquarea+ Premium\", non sarà possibile erogare il servizio, l'abbonamento verrà cancellato e l'importo pagato, restituito.\n",
    contract_complete: "Questo contratto è già stato adempiuto.",
    contract_cancelled: "Questo contratto è stato cancellato.",
    waitingForDateUpdateStatus:"Waiting for response from payment service",
    updateExpiryDate:'Data di scadenza da rinnovare',
    updateFailed:'Aggiornamento della data di scadenza non riuscito - Riprovare più tardi',
    updateSuccess:'Exp.Date updated',
    details: {
        model: "modello",
        orderNumber: "numero ordine utente",
        installationDate: "data di installazione",
        protectionPlan: "pacchetto di servizi",
        location: "indirizzo",
        serialNumber: "numero di serie",
        contractValidTill: "il contratto è valido fino a",
        costs: "importo (IVA inclusa)",
        creditCardNumber:"numero di carta di credito",
        paymentMethod:"metodo di pagamento",
        expiryDate:'Data di scadenza',
        expiryValue:'VALIDO FINO AL'
    },
    btn:{
        upgrade: "aggiornamento del pacchetto di servizi",
        cancel: "disdici l'abbonamento",
        payment_update: "cambiare metodo di pagamento",
        registerHeatpump: "registro pompa di calore",
        registerYourDevice: "registra il tuo dispositivo",
        expiry_update:'AGGIORNARE LA DATA DI SCADENZA',
        updateAllContracts:'Sì Aggiorna',
        cancelUpdate:'No',
    },
    modal:{
        updateContractsTitle:"Aggiornare il/i contratto/i",
        updateContractsBody:"Volete applicare l'aggiornamento della data di scadenza al/i vostro/i contratto/i?",
    },
    accountPage:'/it_IT/home/my_account'
}
