module.exports = {
  headline: "Contrato Aquarea Service+",
  monthly: "mes",
  quarterly: "trimestre",
  annually: "año",
  period: "período",
  table: {
    headers: {
      date: "FECHA",
      price: "PAGO",
      status: "ESTADO",
    },
  },
  PENDING: "PENDIENTE",
  BLOCKED: "BLOQUEADO",
  PAID: "PAGADA",
  CANCELLED: "ANULADA",
  FAILED: "FALLÓ",
  PLANNED: "PREVISTA",
  RETRY: "VUELTA",
  REFUNDED: "REEMBOLSADO",
  OPEN: "ABIERTO",
  viewAll: "Se todos los pagadores",
  REDUCED_FEE:"Discount \"{DISCOUNT_CODE}\" was applied. Amount was deducted from payment scheudule.",
  THREE_MONTHS_FREE: "Discount \"{DISCOUNT_CODE}\" was applied. Amount was deducted from payment scheudule.",
  NO_INSTALLATION_FEE: "Discount \"{DISCOUNT_CODE}\" was applied. Amount was deducted from payment scheudule.",
  UPGRADE_REDUCED_FEE: "Discount \"{DISCOUNT_CODE}\" was applied. Amount was deducted from payment scheudule.",
  UPGRADE_NO_INSTALLATION_FEE: "Discount \"{DISCOUNT_CODE}\" was applied. Amount was deducted from payment scheudule.",
};
