module.exports = {
    taxInformation: "Información Fiscal",
    details: {
        title: "Título",
        firstName: "Nombre",
        lastName: "Apellido",
        name: "Nombre",
        email: "Correo electrónico",
        sendInvoice: "¿enviar factura impresa?",
        address: "Dirección",
        zipCode: "Código postal",
        city: "Ciudad",
        phoneNumber: "Número de teléfono",
        phone: "Número de teléfono",
        taxCodeTypeEmptyOption: "Seleccione el tipo de código fiscal",
        taxCodeType: "Tipo de Código Fiscal",
        taxCode: "Código Fiscal",
    },
    btn: {
        edit: "Editar la información personal",
        back: "Volver a mi cuenta",
        update: "Actualizar la cuenta",
        reset: "Restablecer contraseña"
    },
    editAccount: {
        headline: "Editar la información personal"
    },
    loading: "Cargando ..."
}
