import { useState } from "react";
import i18n from "../../lib/i18n";
import Modal from "../modal";
import FormItem from "../registration/formItem";
import { LoadingSpinnerButton } from "../layout/buttons";
import {
  COUNTRY_CODE_NAME,
  getSessionStorageItem,
} from "../../lib/sessionStorage";

const t = i18n.namespace("TaxCodeModal");
const countryCode = getSessionStorageItem(COUNTRY_CODE_NAME) || "en_IE";

const countrySpecificExactTaxCodeLength = {
  en_es: 9,
  es_es: 9,
  it_it: {
    "Partita IVA": 11,
    "Codice Fiscale": 16,
  },
};

const initialFormState = {
  taxCodeType: { value: "", invalid: true },
  taxCode: { value: "", invalid: true },
};

const TaxCodeModal = ({
  id,
  taxCodeTypes,
  handleUpdateTaxCode,
  askTaxCode,
  setAskTaxCode,
  showErrorMessage,
  setShowErrorMessage,
  isLoading,
}) => {
  const [isFormInvalid, setFormInvalid] = useState(true);
  const [formState, setFormState] = useState(initialFormState);

  const getCountrySpecificExactTaxCodeLength = (taxCodeType) => {
    const countryTaxCodeLength =
      countrySpecificExactTaxCodeLength[countryCode.toLowerCase()];
    if (countryCode.toLowerCase() === "it_it") {
      return countryTaxCodeLength[taxCodeType] || 16;
    }
    return countryTaxCodeLength || "";
  };

  const formItems = [
    {
      type: "select",
      name: "taxCodeType",
      width: "full",
      options: taxCodeTypes || [],
      validators: ["REQUIRED"],
      emptyOption: t("emptyOption"),
    },
    {
      type: "text",
      name: "taxCode",
      width: "full",
      placeholder: t("inputPlaceholder"),
      validators: ["REQUIRED", "EXACT_TAX_CODE_LENGTH"],
      maxLength: getCountrySpecificExactTaxCodeLength(
        formState.taxCodeType.value
      ),
      constraints: {
        exactlyLength: getCountrySpecificExactTaxCodeLength(
          formState.taxCodeType.value
        ),
      },
    },
  ];

  const setValueAndValidity = ({ target: { name, value } }, invalid) => {
    setFormState((formState) => {
      const newFormState = {
        ...formState,
        [name]: { value, invalid },
      };
      if (name === "taxCodeType") {
        newFormState.taxCode = { value: "", invalid: true };
      }
      if (formState[name].value !== value && showErrorMessage === true) {
        setShowErrorMessage(false);
      }
      validateForm(newFormState);
      return newFormState;
    });
  };

  const validateForm = (newFormState) => {
    setFormInvalid(
      Object.keys(newFormState).some((key) => newFormState[key].invalid)
    );
  };

  const handleClose = () => {
    setAskTaxCode(false);
    setShowErrorMessage(false);
    setFormState(initialFormState);
  };

  const modalContent = {
    title: t("modalTitle"),
    body: t("modalBody"),
    component: (
      <>
        {formItems.map((item, index) => (
          <FormItem
            key={index}
            item={item}
            currentValue={formState[item.name].value}
            runInitialValidation
            saveValueByStep={setValueAndValidity}
          />
        ))}
        {showErrorMessage && (
          <span className="modal-error">{t("errorMessage")}</span>
        )}
      </>
    ),
  };

  return (
    <Modal
      id={id}
      content={modalContent}
      isOpen={askTaxCode}
      handleClose={handleClose}
      primaryAction={{
        component: (
          <LoadingSpinnerButton
            isLoading={isLoading}
            text={t("modalPrimaryButton")}
            onClick={() =>
              handleUpdateTaxCode(
                formState.taxCodeType.value,
                formState.taxCode.value
              )
            }
            isEnabled={!isFormInvalid}
            classNames={
              "btn-primary order-1 order-md-2 loading-spinner-button ml-auto"
            }
          />
        ),
      }}
    />
  );
};

export default TaxCodeModal;
