import React from 'react';
import Menu from "../components/menu";
import Footer from "../components/footer";
import i18 from "../lib/i18n";
import EditAccountInformation from "../components/myaccount/editAccountInformation";
import { Page, PageBreadcrumb, PageHeader, PageHeadline } from "../components/layout/page";
import { FormSection } from "../components/layout/form";

const t = i18.namespace("MyAccount");

export const MyAccountEditPage = () => {
  return (
      <Page>
          <Menu/>
          <div className="container">
              <PageHeader>
                  <PageBreadcrumb/>
                  <PageHeadline className="my-account_headline" headline={t("editAccount")} />
              </PageHeader>

              <FormSection>
                  <EditAccountInformation />
              </FormSection>
          </div>
          <Footer/>
      </Page>
  )
}
