module.exports = {
    headline: "Register Heatpump Unit",
    date: {
        title: "Installation date of your heatpump",
        name: "installation date"
    },
    model: {
        title: "Which model is installed?",
        name: "MODEL NAME",
        img: {
            linkText: "WHERE TO FIND?",
            path: "/image/checkout/heatpump.png",
        },
        emptyOption: "-- Choose Model --",
        options: [
            "I don't know",
            "WH-MDC05F3E5",
            "WH-MDC05H3E5",
            "WH-MDC05J3E5",
            "WH-MDC05J3E5HE",
            "WH-MDC06G3E5",
            "WH-MDC06G3E5E",
            "WH-MDC07H3E5",
            "WH-MDC07H3E5E",
            "WH-MDC07J3E5",
            "WH-MDC07J3E5HE",
            "WH-MDC09G3E5",
            "WH-MDC09H3E5",
            "WH-MDC09H3E5E",
            "WH-MDC09J3E5",
            "WH-MDC09J3E5HE",
            "WH-MDC12G6E5",
            "WH-MDC12G6E5E",
            "WH-MDC12H6E5",
            "WH-MDC12H6E5E",
            "WH-MDC12H6E5HE",
            "WH-MDC16G6E5",
            "WH-MDC16H6E5",
            "WH-MDC16H6E5E",
            "WH-MDC16H6E5HE",
            "WH-MDF09E3E5",
            "WH-MHF09G3E5",
            "WH-MHF12G6E5",
            "WH-MHF12G9E8",
            "WH-MXC09G3E5",
            "WH-MXC09G3E5E",
            "WH-MXC09H3E5",
            "WH-MXC09H3E5E",
            "WH-MXC09H3E5HE",
            "WH-MXC09J3E5",
            "WH-MXC09J3E5HE",
            "WH-MXC09J3E8",
            "WH-MXC12G6E5",
            "WH-MXC12G6E5E",
            "WH-MXC12H6E5",
            "WH-MXC12H6E5E",
            "WH-MXC12H6E5HE",
            "WH-MXC12H9E8",
            "WH-MXC12J6E5",
            "WH-MXC12J6E5HE",
            "WH-MXC12J9E8",
            "WH-MXC16G9E8",
            "WH-MXC16H9E8",
            "WH-MXC16J9E8",
            "WH-MXF09D3E5-1",
            "WH-UD03JE5",
            "WH-UD05EE5",
            "WH-UD05HE5-1",
            "WH-UD05HE5-1E",
            "WH-UD05JE5",
            "WH-UD05JE5HE",
            "WH-UD07FE5",
            "WH-UD07HE5-1",
            "WH-UD07HE5-1E",
            "WH-UD07JE5",
            "WH-UD07JE5HE",
            "WH-UD09HE5",
            "WH-UD09HE5-1",
            "WH-UD09HE5-1E",
            "WH-UD09JE5",
            "WH-UD09JE5-1",
            "WH-UD09JE5-1HE",
            "WH-UD12HE5",
            "WH-UD12HE5E",
            "WH-UD12HE5HE",
            "WH-UD12HE8",
            "WH-UD16HE5",
            "WH-UD16HE5E",
            "WH-UD16HE8",
            "WH-UDZ03KE5",
            "WH-UDZ05KE5",
            "WH-UDZ07KE5",
            "WH-UDZ09KE5",
            "WH-UH09FE5",
            "WH-UH12FE5",
            "WH-UQ12HE8",
            "WH-UQ16HE8",
            "WH-UX09HE5",
            "WH-UX09HE5E",
            "WH-UX09HE5HE",
            "WH-UX12FE5",
            "WH-UX12HE5",
            "WH-UX12HE5HE",
            "WH-UX12HE8",
            "WH-UX16HE8",
            "WH-UX16HE8E",
            "WH-UXZ09KE5",
            "WH-UXZ12KE5",
            "WH-WDG05LE5",
            "WH-WDG07LE5",
            "WH-WDG09LE5"
        ]
    },
    serialNumber: {
        name: "SERIAL NUMBER",
        img: {
            linkText: "WHERE TO FIND?",
            path: "/image/checkout/heatpump.png",
        },
    },
    gatewayId: {
        title: "If you have already the Network Adaptor, please enter the Device ID",
        name: "DEVICE ID (OPTIONAL)",
        img: {
            linkText: "WHERE TO FIND?",
            path: "/image/checkout/deviceID.png"
        }
    },
    shippingAddress: {
        title: "Installation Address",
        street: {
            label: "Address",
            name: "street",
        },
        zipcode: {
            label: "Postcode",
            name: "zipcode",
        },
        city: {
            label: "City",
            name: "city",
        },
    },


    btn: {
        back: "BACK TO MY ACCOUNT",
        next: "SAVE"
    },
    errors: {
        futureDate: "the installation date is in the future",
        dateTooFarInPast: "installation date is too early",
        onlyDigits: "only digits allowed",
        lengthIs11: "length of device id must be 11",
        zipCodeMin: "Min 7 digits",
        zipCodeMax: "Max 8 digits",
        serialMin: "Min 8 digits",
        serialMax: "Max 20 digits",
        requiredField: "This field is required",
        addressTooLong: "Maximum 30 characters",
        cityTooLong: "Maximum 35 characters"
    }
};
